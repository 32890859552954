<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import LinesServicesForm from "./partials/LinesServicesForm.vue";

export default {
    components: {
        Breadcrumbs,
        LinesServicesForm
    },
    data() {
        return {
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Linhas", path: "lines.index" },
                { name: "Serviços", path: "lines.services" },
                { name: "Formulário", path: "lines.service.criar" },
            ],
            service: {}
        }
    },
    methods: {

    },
    mounted: function () {
        this.service = JSON.parse(sessionStorage.getItem("serviceForm"));
    }
}
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-2" :crumbs="crumbs" />
            <div class="flex flex-wrap justify-between align-baseline border-b-2 border-blue-base mb-2">
                <h1 class="text-blue-base uppercase pt-5">{{ this.service.name }}</h1>
            </div>
        </div>

        <div class="p-2">
            <template v-if="service.form == 'linesForm'">
                <LinesServicesForm :service="service" />
            </template>
        </div>
    </div>
</template>