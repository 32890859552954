<script>
import SearchSelect from "@/core/components/SearchSelect";

export default {
    components: {
        SearchSelect
    },
    data() {
        return {
            linha: null,
            especificacao: '',
            justificativa: '',
            anexo: null
        };
    },
    methods: {
        submitForm() {
            // console.log("Enviar formulário");
        },
        goBack() {
            this.$router.go(-1);
        },
        handleFileChange(event) {
            this.anexo = event.target.files[0];
        },
        changeLine(line) {
            this.linha = line;
        }
    }
};
</script>

<template>
    <div class="px-4">
        <div class="grid grid-cols-1 px-4 bg-white shadow-md pt-3 pb-10">
            <form @submit.prevent="submitForm">
                <div class="mb-6">
                    <label class="form-label" for="linha">Linha:</label>
                    <div class="w-3/12">
                        <SearchSelect :selected.sync="linha" :search-keys="[]" :search-remote="true" :lazy-load="true"
                            options-url="/api/lines/route/filter/" :digits-before-search="1" option-key="linha"
                            field-class="py-2 h-10" name="nulinharamal" @changed="changeLine"
                            :option-value="linha => linha.nulinharamal + '.' + linha.nuextensao">
                        </SearchSelect>
                    </div>
                </div>
                <div class="mb-6">
                    <label class="form-label" for="especificacao">Especificação do pedido:</label>
                    <textarea class="form-input" id="especificacao" v-model="especificacao" required></textarea>
                </div>
                <div class="mb-6">
                    <label class="form-label" for="justificativa">Justificativa do Pedido:</label>
                    <textarea class="form-input" id="justificativa" v-model="justificativa" required></textarea>
                </div>
                <div class="mb-6">
                    <label class="form-label" for="anexo">Anexo:</label>
                    <input class="form-input w-3/12" type="file" id="anexo" @change="handleFileChange">
                </div>
                <div class="flex gap-3 mt-4">
                    <button class="w-2/12 h-9 form-submit" type="submit">Enviar</button>
                    <button class="w-2/12 flex justify-center items-center btn-gray text-xs uppercase"
                        @click="goBack">Voltar</button>
                </div>
            </form>
        </div>
    </div>
</template>