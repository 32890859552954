<script>
import { lowerCase } from 'lodash';
import SearchSelect from "@/core/components/SearchSelect";
import Select from '@/core/components/Select.vue';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        SearchSelect,
        Select,
        Multiselect
    },
    props: {},
    data: () => {
        return {
            title: "Linhas",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Linhas", path: "lines.index" },
                { name: "Consulta de Linhas", path: "lines.search.search" }
            ],
            lines: [],
            diaDaSemana: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            origin: {},
            destiny: {},
            turnoOptions: [
                { id: 0, name: 'Turno', value: '' },
                { id: 1, name: 'Manhã', begin: '06:00', end: '11:59' },
                { id: 2, name: 'Tarde', begin: '12:00', end: '17:59' },
                { id: 3, name: 'Noite', begin: '18:00', end: '23:59' },
                { id: 4, name: 'Madrugada', begin: '00:00', end: '05:59' }
            ],
            turnoFilter: { id: 0, name: 'Turno', value: '' },
            diaDaSemanaOptions: [
                { id: 0, name: 'Todos', label: ' Todos' },
                { id: 1, name: 'Segunda-feira', label: ' Seg' },
                { id: 2, name: 'Terça-feira', label: ' Ter' },
                { id: 3, name: 'Quarta-feira', label: ' Qua' },
                { id: 4, name: 'Quinta-feira', label: ' Qui' },
                { id: 5, name: 'Sexta-feira', label: ' Sex' },
                { id: 6, name: 'Sábado', label: ' Sáb' },
                { id: 7, name: 'Domingo', label: ' Dom' }
            ],
            diaDaSemanaFilter: [],
            transportadorasOptions: [],
            transportadorasFilter: { id: 0, name: 'Transportadoras' },
        };
    },
    methods: {
        /**
         * Exibe ou oculta o detalhamento da linha
         * @param lineNumber id da linha a ser exibida
         * @param target elemento selecionado
         */
        collapse(lineNumber, target) {
            let targetElement = target.type == 'button' ? target : target.parentElement;
            if (this.$refs[lineNumber][0].classList.contains("hidden")) {
                this.$refs[lineNumber][0].classList.remove("hidden");
                targetElement.classList.remove("rotate");
                targetElement.classList.add("rotate-180");
            } else {
                this.$refs[lineNumber][0].classList.add("hidden");
                targetElement.classList.add("rotate");
                targetElement.classList.remove("rotate-180");
            }
        },
        /**
         * Processa os dados das linhas para exibição, aplicando os filtros selecionados
         * @param data array com os dados das linhas
         */
        processData(data) {
            this.lines = [];
            let tuFilter = this.turnoFilter;
            let tFilter = this.transportadorasFilter;
            let dFilter = this.diaDaSemanaFilter.map(dia => dia.label.trim());
            data.forEach(registry => {
                if (tFilter.id !== 0 && tFilter.name !== (this.capitalize(this.lowerCase(registry.empresa)))) {
                    return;
                }

                // trata tempo de viagem
                let horas = Math.floor(registry.tempo_viagem / 60);
                let minutos = (registry.tempo_viagem);
                if (horas > 0) minutos = (registry.tempo_viagem % 60);
                let tempoDeViagem = minutos + ' min';
                if (horas > 0) {
                    tempoDeViagem = horas + ' h';
                }
                if (horas > 0 && minutos > 0) {
                    tempoDeViagem = horas + ' h e ' + minutos + ' min';
                }
                let tarifa = 'R$' + registry.tarifa.replace('.', ',');

                // trata itinerários
                let itinerarios = [];
                if (registry.itinerarios && registry.itinerarios.length > 0) {
                    let i = 0;
                    // a string itinerarios precisa ser dividiva pelos caracteres , E /
                    itinerarios = registry.itinerarios.split(/[,E/]/).map(itinerario => {
                        return { id: i++, name: itinerario.trim() };
                    })
                }

                //trata paradas
                let paradas = [];
                if (registry.paradas && registry.paradas.length > 0) {
                    let i = 0;
                    paradas = registry.paradas.map(parada => {
                        return { id: i++, name: parada.trim() };
                    });
                }

                Object.keys(registry.horarios).forEach(horario => {
                    // horário no qual o onibus parte do ponto de origem da linha
                    let horarioPartidaLinha = registry.horarios[horario][0].hrpartida;
                    // horário no qual o onibus parte/chega ao ponto de origem selecionado pelo usuário
                    let horarioPartidaPontoDeOrigem = horario;

                    let diasDaSemana = registry.horarios[horario].map(diasPorHorario => {
                        return this.diaDaSemana[diasPorHorario.nudiasemana];
                    });

                    let stringDiasDaSemana = diasDaSemana.join(" - ");

                    // verifica se existe um filtro de turno e se o horário de partida está dentro do filtro
                    if (tuFilter.id !== 0 && !this.isInTurno(tuFilter, horarioPartidaPontoDeOrigem)) {
                        return;
                    }

                    if (dFilter.length === 0 || (dFilter.length > 0 && dFilter.includes('Todos'))) {
                        this.addLine(registry, horarioPartidaLinha, horarioPartidaPontoDeOrigem,
                            stringDiasDaSemana, tempoDeViagem, tarifa, itinerarios, paradas);
                    } else {
                        let match = dFilter.filter(dia => stringDiasDaSemana.includes(dia));
                        if (match.length > 0) {
                            this.addLine(registry, horarioPartidaLinha, horarioPartidaPontoDeOrigem,
                                stringDiasDaSemana, tempoDeViagem, tarifa, itinerarios, paradas);
                        }
                    }
                });
            });
        },
        /**
         * Adiciona uma linha ao array de linhas
         * @param registry registro da linha
         * @param horarioPartidaLinha partida do ponto de origem da linha
         * @param horarioPartidaPontoDeOrigem partida do ponto de origem selecionado
         * @param stringDiasDaSemana dias da semana em que a linha opera
         * @param tempoDeViagem
         * @param tarifa
         * @param itinerarios
         * @param paradas
         */
        addLine(registry, horarioPartidaLinha, horarioPartidaPontoDeOrigem, stringDiasDaSemana,
            tempoDeViagem, tarifa, itinerarios, paradas) {
            let paradasAgrupadas = this.groupParadas(paradas);
            this.lines.push({
                ramal: registry.ramal,
                empresa: this.lowerCase(registry.empresa),
                origem: this.lowerCase(registry.origem),
                destino: this.lowerCase(registry.destino),
                tempo_viagem: tempoDeViagem,
                tarifa: tarifa,
                horario: horarioPartidaLinha.slice(0, 5),
                horarioPartidaPontoDeOrigem: horarioPartidaPontoDeOrigem.slice(0, 5),
                diasDaSemana: stringDiasDaSemana,
                itinerarios: itinerarios,
                paradas: paradas,
                tpVeiculo: this.capitalize(this.lowerCase(registry.tipo_onibus)),
                paradasAgrupadas: paradasAgrupadas
            });
        },
        /**
         * Define se o horário de partida está dentro do turno selecionado
         * @param tuFilter filtro de turno
         * @param horarioPartidaPontoDeOrigem horario de partida do ponto de origem selecionado
         */
        isInTurno(tuFilter, horarioPartidaPontoDeOrigem) {
            let horarioPartida = horarioPartidaPontoDeOrigem.split(':');
            let hora = parseInt(horarioPartida[0]);
            let minuto = parseInt(horarioPartida[1]);
            let horarioPartidaMinutos = hora * 60 + minuto;

            let turnoBegin = tuFilter.begin.split(':');
            let turnoBeginMinutos = parseInt(turnoBegin[0]) * 60 + parseInt(turnoBegin[1]);
            let turnoEnd = tuFilter.end.split(':');
            let turnoEndMinutos = parseInt(turnoEnd[0]) * 60 + parseInt(turnoEnd[1]);
            if (horarioPartidaMinutos < turnoBeginMinutos || horarioPartidaMinutos > turnoEndMinutos) {
                return false;
            }
            return true;
        },
        capitalize(string) {
            let arrayPalavras = string.split(" ");
            let arrayPalavrasCapitalized = arrayPalavras.map(palavra => {
                return palavra.charAt(0).toUpperCase() + palavra.slice(1);
            });
            return arrayPalavrasCapitalized.join(" ");
        },
        lowerCase(string) {
            return lowerCase(string);
        },
        changeOriginSectionPoint(origin) {
            sessionStorage.setItem('sectionPoints', JSON.stringify({
                origin: origin,
                destiny: this.destiny
            }));
        },
        changeDestinySectionPoint(destiny) {
            sessionStorage.setItem('sectionPoints', JSON.stringify({
                origin: this.origin,
                destiny: destiny
            }));
        },
        /**
         * Define as transportadoras únicas disponíveis para filtragem
         * @param data array com os dados das linhas
         */
        setTransportadorasOptions(data) {
            this.transportadorasOptions = [];
            this.transportadorasOptions.push({ id: 0, name: 'Transportadoras' })
            let transportadorasIndex = 1;
            let transportadorasNames = [];
            transportadorasNames.push('Transportadoras');
            data.forEach(registry => {
                if (!transportadorasNames.includes(registry.empresa)) {
                    transportadorasIndex++;
                    transportadorasNames.push(registry.empresa);
                    this.transportadorasOptions.push({ id: transportadorasIndex, name: this.capitalize(this.lowerCase(registry.empresa)) });
                }
            });
        },
        async searchLines() {
            if (this.origin.idpontosecao && this.destiny.idpontosecao) {
                try {
                    const response = await this.$axios.get(
                        "/api/lines/section/" + parseInt(this.origin.idpontosecao) + "/" + parseInt(this.destiny.idpontosecao)
                    );
                    this.setTransportadorasOptions(response.data);
                    this.processData(response.data);
                } catch (e) {
                    this.status = "rejected";
                    this.$Progress.finish();
                }
            }
        },
        /**
         * Troca a origem e o destino da busca
         */
        swapLocations() {
            const origin = this.origin;
            this.origin = this.destiny;
            this.destiny = origin;
            sessionStorage.setItem('sectionPoints', JSON.stringify({
                origin: this.origin,
                destiny: this.destiny
            }));
            this.searchLines();
        },
        /**
         * Agrupa as paradas em grupos de 7 para exibir no detalhamento da linha
         * @param paradas array de paradas
         */
        groupParadas(paradas) {
            let paradasGrouped = [];
            let i = 0;
            paradas.forEach(parada => {
                if (i % 7 === 0) {
                    paradasGrouped.push([]);
                }
                paradasGrouped[paradasGrouped.length - 1].push(parada);
                i++;
            });
            return paradasGrouped;
        },
        /**
         * Limpa os filtros de pesquisa
         */
        cleanFilters() {
            this.transportadorasFilter = { id: 0, name: 'Transportadora' };
            this.turnoFilter = { id: 0, name: 'Turno', value: '' };
            this.diaDaSemanaFilter = [];
        },
    },
    async mounted() {
        const sectionPoints = JSON.parse(sessionStorage.getItem('sectionPoints'));
        if (sectionPoints) {
            this.origin = sectionPoints.origin;
            this.destiny = sectionPoints.destiny;
        }
        const originParam = this.$route.params.origin;
        const destinyParam = this.$route.params.destiny;
        if (originParam && destinyParam) {
            try {
                const response = await this.$axios.get(
                    "/api/lines/section/" + parseInt(originParam) + "/" + parseInt(destinyParam)
                );
                this.setTransportadorasOptions(response.data);
                this.processData(response.data);
            } catch (e) {
                this.status = "rejected";
                this.$Progress.finish();
            }
        }
    },
};
</script>
<style>
.rotate:focus,
.rotate-180:focus {
    outline: 0;
}

.rotate {
    transition: transform 0.2s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    transform: rotate(0deg);
}

.rotate-180 {
    transition: transform 0.2s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    transform: rotate(180deg);
}
.bg-registro {
    background-color: #faf6f6;
}
.bg-detalhe {
    background-color: #e7e6e6;
}
</style>

<template>
    <div class="w-full bg-white">
        <div class="lg:px-2 md:px-2 my-4 sm:px-0">
            <div class="w-full lg:px-24 md:px-24 sm:px-2">
                <div class="flex flex-wrap align-baseline border-b-2 border-blue-base mb-6 justify-cen w-full">
                    <h1 class="text-blue-base pt-5">
                        Consulta as viagens disponíveis entre os municípios do Estado de Santa Catarina
                    </h1>
                </div>
                <form @submit.prevent="searchLines" class="justify w-full mb-4 sm:mx-1 relative right-1">
                    <div class="flex items-center">
                        <div class="w-1/4">
                            <SearchSelect :selected.sync="origin" :search-keys="[]" :search-remote="true"
                                :digits-before-search=3 :lazy-load="true" options-url="/api/lines/section-point/"
                                option-key="origin" optional-placeholder-icon="icon_flag.svg"
                                optional-carret-icon="icon_caret.svg" field-class="py-2" name="origin"
                                placeholder="Selecione a origem..." @changed="changeOriginSectionPoint"
                                :option-value="sectionPoint => capitalize(lowerCase(sectionPoint.nmlocalidade)) + ' - ' + capitalize(lowerCase(sectionPoint.delocalidade))">
                            </SearchSelect>
                        </div>
                        <button @click="swapLocations" class="shadow-md drop-shadow-lg rounded-full mx-7 p-3">
                            <img class="h-6 justify-self-center" src="@/core/assets/images/icon_navigation.svg" />
                        </button>
                        <div class="w-1/4">
                            <SearchSelect :selected.sync="destiny" :search-keys="[]" :search-remote="true"
                                :digits-before-search="3" :lazy-load="true" options-url="/api/lines/section-point/"
                                option-key="destiny" optional-placeholder-icon="icon_location.svg"
                                optional-carret-icon="icon_caret.svg" field-class="py-2" name="destiny"
                                placeholder="Selecione o destino..." @changed="changeDestinySectionPoint"
                                :option-value="sectionPoint => capitalize(lowerCase(sectionPoint.nmlocalidade)) + ' - ' + capitalize(lowerCase(sectionPoint.delocalidade))">
                            </SearchSelect>
                        </div>
                    </div>
                    <h1 class="text-blue-base pt-5 pb-2">
                        Filtre sua pesquisa
                    </h1>
                    <div class="grid grid-cols-4 gap-5">
                        <div>
                            <Select :divClass="'w-full'" :selectClass="'w-full form-select h-9 py-2'"
                                :customCaret="true" :options="turnoOptions" :selected.sync="turnoFilter"
                                optional-placeholder-icon="icon_time.svg" :optionKey="turno => turno"></Select>
                        </div>
                        <div>
                            <multiselect v-model="diaDaSemanaFilter" :options="diaDaSemanaOptions" :multiple="true"
                                :close-on-select="false" selectLabel="Clique para selecionar"
                                selectedLabel="Selecionado" deselectLabel="Clique para remover" track-by="name"
                                label="name" :clear-on-select="false" :preserve-search="true"
                                placeholder="Dias da semana" :preselect-first="false">
                                <template #selection="{ values, isOpen }">
                                    <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                                        {{ values.map(dia => dia.label).toString() }}
                                    </span>
                                </template>
                            </multiselect>
                        </div>
                        <div>
                            <Select :divClass="'w-full'" :selectClass="'w-full form-select h-9 py-2'"
                                :customCaret="true" :options="transportadorasOptions"
                                :selected.sync="transportadorasFilter" optional-placeholder-icon="icon_bus.svg"
                                :optionKey="transportadora => transportadora"></Select>
                        </div>
                        <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 sm:w-full justify-end lg:mr-3 ">
                            <button
                                class="flex sm:col-span-1 md:col-span-1 lg:col-span-1 justify-center items-center btn-gray text-xs uppercase"
                                type="button" v-on:click="cleanFilters">
                                Limpar
                            </button>
                            <button class="flex justify-center items-center ml-1 form-submit" type="submit">
                                Pesquisar
                            </button>
                        </div>
                    </div>
                </form>
                <div class="flex flex-wrap align-baseline border-b-2 border-blue-base mb-6 justify-cen w-full"></div>
                <template v-if="lines.length != 0">
                    <h3>Linhas que passam entre os municípios de {{ capitalize(lowerCase(origin.nmlocalidade)) }} e {{
                        capitalize(lowerCase(destiny.nmlocalidade)) }}.</h3>
                </template>
                <div class="flex flex-col w-full justify-center">
                    <template v-if="lines.length === 0">
                        <div class="flex justify-center">
                            <p class="text-gray-600">Nenhuma linha encontrada</p>
                        </div>
                    </template>
                    <template v-for="(line, index) in lines">
                        <div :key="`${line.ramal}-${index}-details`" class="mb-2 rounded-lg">
                            <div class="bg-registro text-slate-700 p-3">
                                <div class="flex">
                                    <div class="lg:w- md:w-1/3 sm:1/3 flex">
                                        <div class="justify-self-start">
                                            <p class="text-sm text-gray-600">Linha</p>
                                            <p class="mb-2 text-lg flex items-center">
                                                {{ line.ramal }}
                                                <span class="text-gray-400 mx-2"> | </span>
                                                {{ capitalize(line.origem) }}
                                                <img class="mx-2 h-3" src="@/core/assets/images/icon_navigation.svg" />
                                                {{ capitalize(line.destino) }}
                                            </p>
                                            <p class="text-sm text-gray-600">Empresa</p>
                                            <p class="mb-2 text-lg">{{ capitalize(line.empresa) }}</p>
                                        </div>
                                    </div>
                                    <div class="w-1/4 md:w-1/4 sm:w-auto flex">
                                        <div class="justify-self-start">
                                            <p class="text-sm text-gray-600">Horário de partida da linha</p>
                                            <p class="mb-2 text-lg">{{ line.horario }}</p>
                                            <p class="text-sm text-gray-600">Previsão de passagem em {{
                                                capitalize(lowerCase(origin.nmlocalidade)) }}</p>
                                            <p class="text-lg">{{ line.horarioPartidaPontoDeOrigem }}</p>
                                        </div>
                                    </div>
                                    <div class="w-1/6 md:w-1/6 sm:w-auto flex">
                                        <div class="justify-self-start">
                                            <p class="text-sm text-gray-600">Tarifas</p>
                                            <p class="mb-2 text-lg">{{ line.tarifa }}</p>
                                            <p class="text-sm text-gray-600">Tempo de viagem</p>
                                            <p class="text-lg">{{ line.tempo_viagem }}</p>
                                        </div>
                                    </div>
                                    <div class="w-1/6 md:w-40 sm:w-auto flex">
                                        <div class="justify-self-start">
                                            <p class="text-sm text-gray-600">Dia da semana</p>
                                            <p class="mb-2 text-lg">{{ line.diasDaSemana }}</p>
                                        </div>
                                    </div>
                                    <!-- <div class="ls:w-36 md:w-36 sm:w-auto flex">
                                        <div class="justify-self-start">
                                            <p class="text-sm text-gray-600">Mais informações</p>
                                        </div>
                                    </div> -->
                                    <div class="w-14">
                                        <button class="relative left-1/2 top-1/3" type="button"
                                            v-on:click="collapse(`${line.ramal}.${index}`, $event.target)">
                                            <img class="h-9" src="@/core/assets/images/icon_caret.svg" />
                                        </button>
                                    </div>
                                </div>
                                <div :ref="`${line.ramal}.${index}`" :id="`${line.ramal}.${index}`"
                                    class="hidden w-full justify-center rounded-sm bg-detalhe" :key="line.ramal">
                                    <div class="flex p-2">
                                        <div class="lg:w-2/6 md:w-auto sm:w-auto flex">
                                            <div class="justify-self-start">
                                                <p class="text-sm text-gray-600">Saindo</p>
                                                <p class="mb-2 text-lg flex items-center">{{
                                                    capitalize(lowerCase(line.origem)) }}</p>
                                                <p class="text-sm text-gray-600">Nº Linha</p>
                                                <p class="mb-2 text-lg">{{ line.ramal.split('.')[0] }}</p>
                                                <p class="text-sm text-gray-600">Ramal</p>
                                                <p class="mb-2 text-lg">{{ line.ramal.split('.')[1] }}</p>
                                                <p class="text-sm text-gray-600">Tipo de Ônibus</p>
                                                <p class="mb-2 text-lg">{{ line.tpVeiculo }}</p>
                                            </div>
                                        </div>
                                        <div class="w-1/6 md:w-1/6 sm:w-auto flex">
                                            <div class="justify-self-start">
                                                <p class="text-sm text-gray-600">Itinerário</p>
                                                <template v-if="line.itinerarios.length === 0">
                                                    <p class="mb-2 text-lg">Itinerário não disponível</p>
                                                </template>
                                                <template v-else>
                                                    <p v-for="itinerario in line.itinerarios" :key="itinerario.id"
                                                        class="mb-2 text-sm">
                                                        {{ itinerario.name }}
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="w-2/6 md:w-2/6 sm:w-auto flex">
                                            <div class="grid w-full">
                                                <p class="text-sm text-gray-600">Paradas</p>
                                                <template v-if="line.paradas.length === 0">
                                                    <p class="mb-2 text-lg">Paradas não disponíveis</p>
                                                </template>
                                                <template v-else>
                                                    <div class="flex">
                                                        <template v-for="(grupo, index) in line.paradasAgrupadas">
                                                            <div :key="`${index}`" class="mr-36">
                                                                <div v-for="parada in grupo" :key="parada.id">
                                                                    <p class="mb-2 text-sm whitespace-no-wrap">
                                                                        {{ capitalize(lowerCase(parada.name)) }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
