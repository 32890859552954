<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import { TipoBeneficiario } from "./Step01.vue";
import { format, isValid, parse } from "date-fns";
import { mask } from "@/core/services/helpers";
import { TheMask } from "vue-the-mask";
import Select from "@/core/components/Select";
import Icon from "@/core/components/Icon";
import SearchSelect from "@/core/components/SearchSelect";
import { getAddressByCep } from "../getAddressByCep";
import EstudanteForm from "./components/EstudanteForm.vue";
import TrabalhadorForm from "./components/TrabalhadorForm.vue";
import EstagiarioForm from "./components/EstagiarioForm.vue";
import ContactsList from "./components/ContactsList.vue";
import Vue from "vue";

export default {
    components: {
        Breadcrumbs,
        TheMask,
        Select,
        Icon,
        EstudanteForm,
        ContactsList,
        TrabalhadorForm,
        EstagiarioForm,
        "search-select": SearchSelect
    },
    data: () => {
        return {
            obs: "",
            filiacaoOptions: [
                { id: 1, name: "MÃE" },
                { id: 2, name: "PAI" },
                { id: 3, name: "TIO" },
                { id: 4, name: "RESPONSÁVEL" }
            ],
            TipoBeneficiario,
            tiposBeneficiario: [
                { name: "Estudante", id: TipoBeneficiario.ESTUDANTE.toString() },
                { name: "Estagiário", id: TipoBeneficiario.ESTAGIARIO.toString() },
                { name: "Trabalhador", id: TipoBeneficiario.TRABALHADOR.toString() }
            ],
            tipoCursos: [
                { name: "Fundamental", id: 1 },
                { name: "Médio", id: 2 },
                { name: "Superior", id: 3 },
                { name: "Especialização", id: 4 },
                { name: "Técnico", id: 5 }
            ],
            title: "SPL Navegantes",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "SPL Navegantes", path: "spl-navegantes.show" },
                { name: "Editar", path: "spl-navegantes.index" }
            ],
            alerts: [
                "Consulte a equipe técnica em caso de dúvida ou correção."
            ],
            id: 0,
            getBeneficiarioLoading: true,
            getContatoLoading: true,
            situations: [
                { id: 1, label: "INICIADO", color: "#DC904A" },
                { id: 2, label: "ANALISADO", color: "#028C4A" },
                { id: 3, label: "ATIVO", color: "#028C4A" },
                { id: 4, label: "VENCIDO", color: "#DA3E3E" },
                { id: 5, label: "PENDENTE", color: "#DC904A" },
                { id: 6, label: "CANCELADO", color: "#DA3E3E" },
                { id: 7, label: "A VENCER", color: "#DA3E3E" }
            ],
            beneficiario: {
                nuusuario: "",
                nmnome: "",
                dtnascimento: "",
                idsituacao: "",
                document: "",
                numunicipio: "",
                tppasse: "",
                fljornada: "",
                filiacao: [{
                    idfiliacao: '',
                    document: '',
                    street: '',
                    number: '',
                    district: '',
                    city_id: ''
                }],
                contatos: [
                    {
                        emails: [],
                        phones: []
                    }
                ],
                contato_filiacao: {
                    email: [""],
                    telefone: [""]
                },
                tpbeneficiario: 0,
                tpbeneficiario_inicial: 0,
                controle_registro: []
            },
            enderecoInstituicao: {
                cep: "",
                street: "",
                number: "",
                district: "",
                complement: "",
                numunicipio: {
                    name: "",
                    state: {
                        name: ""
                    }
                }
            },
            enderecoInstituicaoForm: null,
            contatosBeneficiarioForm: [
                {
                    emails: [],
                    phones: []
                }
            ],
            contatosInsituicao:
            {
                emails: [{
                    'contact': '',
                    'id': ''
                }],
                phones: [{
                    'contact': '',
                    'id': ''
                }]
            }
            ,
            contatosInsituicaoForm: [
                {
                    emails: [],
                    phones: []
                }
            ],
            contatosFiliacaoForm: {
                emails: [{
                    'contact': '',
                    'id': ''
                }],
                phones: [{
                    'contact': '',
                    'id': ''
                }]
            },
            filiacaoMunicipio: "",
            beneficiarioEstudante: {
                nminstituicao: '',
                document: '',
                cep: "",
                street: "",
                number: "",
                district: "",
                municipio: ""
            },
            beneficiarioEstudanteForm: {
                nminstituicao: '',
                document: '',
                cep: "",
                street: "",
                number: "",
                district: "",
                municipio: ""
            },
            beneficiarioTrabalhador: {
                nminstituicao: '',
                document: '',
                cep: "",
                street: "",
                number: "",
                district: "",
                municipio: ""
            },
            beneficiarioTrabalhadorForm: {
                nminstituicao: '',
                document: '',
                cep: "",
                street: "",
                number: "",
                district: "",
                municipio: "",
                isValidAdmissionDate: true
            },
            beneficiarioEstagiario: {
                nminstituicao: '',
                document: '',
                cep: "",
                street: "",
                number: "",
                district: "",
                municipio: ""
            },
            beneficiarioEstagiarioForm: {
                nminstituicao: '',
                document: '',
                cep: "",
                street: "",
                number: "",
                district: "",
                municipio: "",
                isValidAdmissionDate: true,
                isValidClosureDate: true
            },
            //status de edição
            isBeneficiarioEdit: false,
            isObservacaoEdit: false,
            isContatoEdit: false,
            isFiliacaoEdit: [false],
            isFiliacaoContatoEdit: false,
            isTrabalhadorOuEstudanteEdit: false,
            isContatoInstituicaoEdit: false,
            //
            beneficiarioForm: null,
            hasErrors: false,
            hasEstudanteErrors: false,
            hasTrabalhadorErrors: false,
            hasEstagiarioErrors: false,
            hasFiliacaoErrors: false,
            hasAdditionalConditions: false,
            obs_id: "",
            observacao: "",
            observacao_pendencia: "",
            ispendencia: false,
            mostrarPopupPendencia: false,
            modalEntregaDocumentosData: {
                title: null,
                action: null
            },
            validBirthDate: true,
        };
    },
    methods: {
        format,
        mask,
        ordenarFiliacaoPorCpfNaoNulo(filiacao) {
            let filiacaoOrdenada = filiacao.sort((a, b) => {
                if (a.document != "" && !b.document) {
                    return -1;
                }
                if (!a.document && b.document != "") {
                    return 1;
                }
                return 0;
            });
            return filiacaoOrdenada;
        },
        async getBeneficiario() {
            this.$Progress.start();
            try {
                const { data } = await this.$axios.get(
                    `/api/spl-navegantes/beneficiarios/dados/${this.id}`
                );

                this.obs = data.observacao ? data.observacao.deobservacao : "";
                this.obs_id = data.observacao ? data.observacao.idobservacao : "";
                this.obs_pendencia = data.observacao_pendencia ? data.observacao_pendencia.deobservacao : "";
                const { emails, phones } = data.usuario;
                this.beneficiario.nmnome = data.nmnome;
                this.beneficiario.document = data.usuario.document_formatted;
                this.beneficiario.cep = data.usuario.cep;
                this.beneficiario.street = data.usuario.street;
                this.beneficiario.number = data.usuario.number;
                this.beneficiario.complement = data.usuario.complement;
                this.beneficiario.district = data.usuario.district;
                this.beneficiario.numunicipio = data.usuario.city;
                this.beneficiario.tplocomocao = data.tplocomocao;
                this.beneficiario.fljornada = data.fljornada;
                this.beneficiario.contatos = {
                    emails: emails.length > 0 ? emails.map(({ contact, id }) => ({ contact, id })) : [{ contact: "", id: "" }],
                    phones: phones.length > 0 ? phones.map(({ contact, id }) => ({ contact, id })) : [{ contact: "", id: "" }]
                };
                if (data.filiacao.length == 0) {
                    this.beneficiario.filiacao = [{
                        idfiliacao: '',
                        document: '',
                        street: '',
                        number: '',
                        district: '',
                        city_id: '',
                        contatos: {
                            emails: [{ contact: "", id: "" }],
                            phones: [{ contact: "", id: "" }]
                        }
                    }]
                } else {
                    let filiacaoOrdenadaPorCpf = this.ordenarFiliacaoPorCpfNaoNulo(data.filiacao);
                    this.beneficiario.filiacao = filiacaoOrdenadaPorCpf.map(filiacao => ({
                        street: '',
                        cep: '',
                        document: '',
                        ...filiacao,
                        idfiliacao: filiacao.idfiliacao,
                        nmnome: filiacao.nmnome,
                        contatos: {
                            emails:
                                filiacao.emails !== undefined
                                    ? filiacao.emails.map(({ contact, id }) => ({
                                        contact,
                                        id
                                    }))
                                    : [{ contact: "", id: "" }],
                            phones:
                                filiacao.phones !== undefined
                                    ? filiacao.phones.map(({ contact, id }) => ({
                                        contact,
                                        id
                                    }))
                                    : [{ contact: "", id: "" }]
                        }
                    }));
                    this.filiacaoMunicipio = data.filiacao[0].city ?? "";
                }


                this.beneficiario.dtcadastro = data.dtcadastro;
                this.beneficiario.dtnascimento = format(
                    new Date(data.dtnascimento),
                    "dd/MM/yyyy"
                );
                this.beneficiario.nuusuario = data.nuusuario;
                this.beneficiario.tpbeneficiario = data.tpbeneficiario;
                this.beneficiario.tpbeneficiario_inicial = data.tpbeneficiario;
                if (data.controle_registro) {
                    this.beneficiario.idsituacao =
                        data.controle_registro[0].idsituacao;
                    this.beneficiario.controle_registro = data.controle_registro[0];
                }
                this.beneficiarioForm = JSON.parse(
                    JSON.stringify(this.beneficiario)
                );

                const instituicaoData = {
                    document: data.instituicao ? data.instituicao.document : '',
                    nminstituicao: data.instituicao ? data.instituicao.name : ''
                };

                if (data.doacao) {
                    this.beneficiario.tppasse = data.doacao[0].tppasse
                }
                if (data.instituicao) {
                    this.contatosInsituicao = {
                        emails: data.instituicao.emails.length > 0 ? data.instituicao.emails.map(({ contact, id }) => ({ contact, id })) : [{ contact: "", id: "" }],
                        phones: data.instituicao.phones.length > 0 ? data.instituicao.phones.map(({ contact, id }) => ({ contact, id })) : [{ contact: "", id: "" }]
                    }
                    this.contatosInsituicaoForm = {
                        emails: data.instituicao.emails.length > 0 ? data.instituicao.emails.map(({ contact, id }) => ({ contact, id })) : [{ contact: "", id: "" }],
                        phones: data.instituicao.phones.length > 0 ? data.instituicao.phones.map(({ contact, id }) => ({ contact, id })) : [{ contact: "", id: "" }]
                    }
                    this.enderecoInstituicao.cep = data.instituicao.cep;
                    this.enderecoInstituicao.complement =
                        data.instituicao.complement;
                    this.enderecoInstituicao.district = data.instituicao.district;
                    this.enderecoInstituicao.number = data.instituicao.number;
                    this.enderecoInstituicao.street = data.instituicao.street;
                    this.enderecoInstituicao.numunicipio = data.instituicao.city;
                }
                if (Number(this.beneficiario.tpbeneficiario) > 0) {
                    switch (Number(this.beneficiario.tpbeneficiario)) {
                        case TipoBeneficiario.ESTUDANTE:
                            this.beneficiarioEstudante = {
                                ...data.estudante,
                                ...instituicaoData
                            };

                            if (data.instituicao) {
                                this.beneficiarioEstudante.cep = data.instituicao.cep;
                                this.beneficiarioEstudante.complement = data.instituicao.complement;
                                this.beneficiarioEstudante.district = data.instituicao.district;
                                this.beneficiarioEstudante.number = data.instituicao.number;
                                this.beneficiarioEstudante.street = data.instituicao.street;
                                this.beneficiarioEstudante.municipio = data.instituicao.city;
                            }
                            this.beneficiarioEstudanteForm = {
                                ...this.beneficiarioEstudante,
                                ...instituicaoData
                            };

                            break;
                        case TipoBeneficiario.TRABALHADOR:
                            if (data.trabalhador) {
                                this.beneficiarioTrabalhador = {
                                    ...data.trabalhador,
                                    ...instituicaoData,
                                    dtadmissao: format(
                                        new Date(data.trabalhador.dtadmissao),
                                        "dd/MM/yyyy"
                                    )
                                };
                            }
                            if (data.instituicao) {
                                this.beneficiarioTrabalhador.cep = data.instituicao.cep;
                                this.beneficiarioTrabalhador.complement = data.instituicao.complement;
                                this.beneficiarioTrabalhador.district = data.instituicao.district;
                                this.beneficiarioTrabalhador.number = data.instituicao.number;
                                this.beneficiarioTrabalhador.street = data.instituicao.street;
                                this.beneficiarioTrabalhador.municipio = data.instituicao.city;
                                this.beneficiarioTrabalhador.nminstituicao = data.instituicao.name;
                                this.beneficiarioTrabalhador.document = data.instituicao.document;
                            }
                            if (this.beneficiarioTrabalhador.vlsalario == null)
                                this.beneficiarioTrabalhador.vlsalario = 0;
                            this.beneficiarioTrabalhadorForm = {
                                ...this.beneficiarioTrabalhador,
                                ...instituicaoData
                            };
                            this.beneficiarioTrabalhadorForm.isValidAdmissionDate = true;
                            break;
                        case TipoBeneficiario.ESTAGIARIO:
                            this.beneficiarioEstagiario = {
                                ...data.estagiario,
                                ...instituicaoData,
                                dtadmissao: format(
                                    new Date(data.estagiario.dtadmissao),
                                    "dd/MM/yyyy"
                                ),
                                dtencerramento: format(
                                    new Date(data.estagiario.dtencerramento),
                                    "dd/MM/yyyy"
                                )
                            };
                            if (data.instituicao) {
                                this.beneficiarioEstagiario.cep = data.instituicao.cep;
                                this.beneficiarioEstagiario.complement = data.instituicao.complement;
                                this.beneficiarioEstagiario.district = data.instituicao.district;
                                this.beneficiarioEstagiario.number = data.instituicao.number;
                                this.beneficiarioEstagiario.street = data.instituicao.street;
                                this.beneficiarioEstagiario.municipio = data.instituicao.city;
                            }
                            this.beneficiarioEstagiarioForm = {
                                ...this.beneficiarioEstagiario,
                                ...instituicaoData
                            };
                            this.beneficiarioEstagiarioForm.isValidAdmissionDate = true;
                            this.beneficiarioEstagiarioForm.isValidClosureDate = true;
                            break;
                    }
                }
                sessionStorage.setItem(
                    "beneficiarioEdit",
                    JSON.stringify(this.beneficiario)
                );

                sessionStorage.setItem(
                    "beneficiarioInstituicao",
                    JSON.stringify(instituicaoData)
                );
                sessionStorage.setItem("obs", this.obs);
            } catch (e) {
                if (e.response) {
                    this.$toast.error({
                        message:
                            e.response?.data?.message || e.response?.data?.error
                    });
                } else {
                    this.$toast.error({ message: "Erro desconhecido" });
                }
            } finally {
                this.getBeneficiarioLoading = false;
                this.$Progress.finish();
            }
        },
        onCpfChangeFiliacao(index, cpf) {
            this.beneficiarioForm.filiacao[index].document = cpf;
            this.isFormValid();
        },
        async onCepChangeFiliacao(index) {
            if (this.beneficiarioForm.filiacao[index].cep.length === 8) {
                this.$Progress.start();
                const {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    ibge
                } = await getAddressByCep(this.beneficiarioForm.filiacao[index].cep);
                if (logradouro) {
                    this.beneficiarioForm.filiacao[index].street = logradouro;
                    this.beneficiarioForm.filiacao[index].district = bairro;
                    this.beneficiarioForm.filiacao[index].city = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    }
                    this.isFormValid();
                } else {
                    this.$toast.error({
                        message: "CEP Inválido."
                    });
                }
                this.$Progress.finish();
            }
        },
        async onCepChangeBeneficiario(cep) {
            if (cep.length === 8) {
                this.$Progress.start();
                const {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    ibge
                } = await getAddressByCep(cep);
                if (logradouro) {
                    this.beneficiarioForm.street = logradouro;
                    this.beneficiarioForm.district = bairro;
                    this.beneficiarioForm.numunicipio = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    }
                } else {
                    this.$toast.error({
                        message: "CEP Inválido."
                    });
                }
                this.$Progress.finish();
            }
        },
        async postDisablePending() {
            try {
                await this.$axios.put(
                    "/api/spl-navegantes/beneficiarios/remover/pendencia/" + this.id
                );

                sessionStorage.clear();
                this.$toast.success({
                    title: "OK",
                    message: "Pendências removidas com sucesso"
                });
            } catch (error) {
                this.$toast.error({
                    message: "Error"
                });
            }
        },
        getParentesco(tpparentesco) {
            if (tpparentesco) {
                return this.filiacaoOptions.find(
                    item => Number(item.id) === Number(tpparentesco)
                ).name;
            }
        },
        getCityStateNameByCode(code) {
            const ValidCityCodes = {
                NAVEGANTES: 4583,
                ITAJAI: 4540
            };
            if (code === ValidCityCodes.ITAJAI) {
                return "Itajaí / SC";
            } else if (code === ValidCityCodes.NAVEGANTES) {
                return "Navegantes / SC";
            }
            return "-";
        },
        async handleEditBeneficiario() {
            this.$Progress.start();
            const body = await this.getEditBody();
            try {
                await this.$axios.put(
                    `/api/spl-navegantes/beneficiarios/editar/${this.id}`,
                    body
                );
                this.$toast.success({
                    title: "OK",
                    message: "Dados editados com sucesso"
                });
                this.$router.go(0);
                //this.$router.push({ name: "spl-navegantes.show" });
            } catch (e) {
                if (e.response) {
                    this.$toast.error({
                        message:
                            e.response?.data?.message || e.response?.data?.error
                    });
                } else {
                    this.$toast.error({ message: "Erro desconhecido" });
                }
            } finally {
                this.$Progress.finish();
            }
        },
        async getEditBody() {
            switch (Number(this.beneficiario.tpbeneficiario)) {
                case TipoBeneficiario.ESTUDANTE:
                    return {
                        nmnome: this.beneficiarioForm.nmnome,
                        dtnascimento: this.formatDate(
                            this.beneficiarioForm.dtnascimento
                        ),
                        tpbeneficiario: Number(
                            this.beneficiario.tpbeneficiario
                        ),
                        usuario: {
                            document: this.beneficiario.document,
                            nmnome: this.beneficiarioForm.nmnome,
                            cep: this.beneficiarioForm.cep,
                            street: this.beneficiarioForm.street,
                            number: this.beneficiarioForm.number,
                            district: this.beneficiarioForm.district,
                            complement: this.beneficiarioForm.complement,
                            municipio: this.beneficiario.numunicipio,
                            phones: this.contatosBeneficiarioForm.phones,
                            emails: this.contatosBeneficiarioForm.emails
                        },
                        filiacao: this.beneficiarioForm.filiacao.map(
                            (
                                {
                                    idfiliacao,
                                    document,
                                    nmnome,
                                    tpparentesco,
                                    cep,
                                    number,
                                    street,
                                    district,
                                    city: municipio,
                                    complement
                                },
                                index
                            ) => ({
                                idfiliacao,
                                document,
                                nmnome,
                                tpparentesco,
                                cep,
                                number,
                                district,
                                street,
                                complement,
                                municipio,
                                phones: this.beneficiarioForm.filiacao[index].contatos.phones,
                                emails: this.beneficiarioForm.filiacao[index].contatos.emails
                            })
                        ),
                        instituicao: {
                            nmnome: this.beneficiarioEstudanteForm
                                .nminstituicao,
                            tpinstituicao: 3,
                            document: this.beneficiarioEstudanteForm.document,
                            cep: this.beneficiarioEstudanteForm.cep,
                            street: this.beneficiarioEstudanteForm.street,
                            number: this.beneficiarioEstudanteForm.number,
                            district: this.beneficiarioEstudanteForm.district,
                            complement: this.beneficiarioEstudanteForm.complement,
                            municipio: this.beneficiarioEstudanteForm.municipio,
                            phones: this.contatosInsituicaoForm.phones,
                            emails: this.contatosInsituicaoForm.emails
                        },
                        observacao: {
                            idobservacao: this.obs_id,
                            deobservacao: this.obs
                        },
                        tpcurso: this.beneficiarioEstudanteForm.tpcurso,
                        nuseriecurso: this.beneficiarioEstudanteForm
                            .nuseriecurso,
                        idcurso:
                            this.beneficiarioEstudanteForm.curso ? this.beneficiarioEstudanteForm.curso.idcurso : ''
                    };
                case TipoBeneficiario.ESTAGIARIO:
                    return {
                        nmnome: this.beneficiarioForm.nmnome,
                        dtnascimento: this.formatDate(
                            this.beneficiarioForm.dtnascimento
                        ),
                        tpbeneficiario: Number(
                            this.beneficiario.tpbeneficiario
                        ),
                        usuario: {
                            document: this.beneficiario.document,
                            nmnome: this.beneficiarioForm.nmnome,
                            cep: this.beneficiarioForm.cep,
                            street: this.beneficiarioForm.street,
                            number: this.beneficiarioForm.number,
                            district: this.beneficiarioForm.district,
                            complement: this.beneficiarioForm.complement,
                            municipio: this.beneficiario.numunicipio,
                            phones: this.contatosBeneficiarioForm.phones,
                            emails: this.contatosBeneficiarioForm.emails
                        },
                        filiacao: this.beneficiarioForm.filiacao.map(
                            (
                                {
                                    idfiliacao,
                                    document,
                                    nmnome,
                                    tpparentesco,
                                    cep,
                                    number,
                                    street,
                                    district,
                                    city: municipio,
                                    complement
                                },
                                index
                            ) => ({
                                idfiliacao,
                                document,
                                nmnome,
                                tpparentesco,
                                cep,
                                number,
                                district,
                                street,
                                complement,
                                municipio,
                                phones: this.beneficiarioForm.filiacao[index].contatos.phones,
                                emails: this.beneficiarioForm.filiacao[index].contatos.emails
                            })
                        ),
                        instituicao: {
                            nmnome: this.beneficiarioEstagiarioForm
                                .nminstituicao,
                            document: this.beneficiarioEstagiarioForm.document,
                            tpinstituicao: 3,
                            cep: this.beneficiarioEstagiarioForm.cep,
                            street: this.beneficiarioEstagiarioForm.street,
                            number: this.beneficiarioEstagiarioForm.number,
                            district: this.beneficiarioEstagiarioForm.district,
                            complement: this.beneficiarioEstagiarioForm.complement,
                            municipio: this.beneficiarioEstagiarioForm.municipio,
                            phones: this.contatosInsituicaoForm.phones,
                            emails: this.contatosInsituicaoForm.emails
                        },
                        observacao: {
                            idobservacao: this.obs_id,
                            deobservacao: this.obs
                        },
                        dtadmissao: this.formatDate(
                            this.beneficiarioEstagiarioForm.dtadmissao
                        ),
                        dtencerramento: this.formatDate(
                            this.beneficiarioEstagiarioForm.dtencerramento
                        )
                    };

                case TipoBeneficiario.TRABALHADOR:
                    return {
                        nmnome: this.beneficiarioForm.nmnome,
                        dtnascimento: this.formatDate(
                            this.beneficiarioForm.dtnascimento
                        ),
                        tpbeneficiario: Number(
                            this.beneficiario.tpbeneficiario
                        ),
                        usuario: {
                            document: this.beneficiario.document,
                            nmnome: this.beneficiarioForm.nmnome,
                            cep: this.beneficiarioForm.cep,
                            street: this.beneficiarioForm.street,
                            number: this.beneficiarioForm.number,
                            district: this.beneficiarioForm.district,
                            complement: this.beneficiarioForm.complement,
                            municipio: this.beneficiario.numunicipio,
                            phones: this.contatosBeneficiarioForm.phones,
                            emails: this.contatosBeneficiarioForm.emails
                        },
                        filiacao: this.beneficiarioForm.filiacao.map(
                            (
                                {
                                    idfiliacao,
                                    document,
                                    nmnome,
                                    tpparentesco,
                                    cep,
                                    number,
                                    street,
                                    district,
                                    complement,
                                    city: municipio
                                },
                                index
                            ) => ({
                                idfiliacao,
                                document,
                                nmnome,
                                tpparentesco,
                                cep,
                                number,
                                district,
                                street,
                                complement,
                                municipio,
                                phones: this.beneficiarioForm.filiacao[index].contatos.phones,
                                emails: this.beneficiarioForm.filiacao[index].contatos.emails
                            })
                        ),
                        instituicao: {
                            nmnome: this.beneficiarioTrabalhadorForm
                                .nminstituicao,
                            document: this.beneficiarioTrabalhadorForm.document,
                            tpinstituicao: 3,
                            cep: this.beneficiarioTrabalhadorForm.cep,
                            street: this.beneficiarioTrabalhadorForm.street,
                            number: this.beneficiarioTrabalhadorForm.number,
                            district: this.beneficiarioTrabalhadorForm.district,
                            complement: this.beneficiarioTrabalhadorForm.complement,
                            municipio: this.beneficiarioTrabalhadorForm.municipio,
                            phones: this.contatosInsituicaoForm.phones,
                            emails: this.contatosInsituicaoForm.emails
                        },
                        observacao: {
                            idobservacao: this.obs_id,
                            deobservacao: this.obs
                        },
                        ispendencia: true,
                        tplocomocao: 1,
                        idprofissao: this.beneficiarioTrabalhadorForm
                            .profissao.idprofissao,
                        vlsalario: this.beneficiarioTrabalhadorForm.vlsalario,
                        dtadmissao: this.formatDate(
                            this.beneficiarioTrabalhadorForm.dtadmissao
                        )
                    };
            }
        },
        goBack() {
            this.$router.push({ name: "spl-navegantes.show" });
        },
        validateBeneficiarioCEP() {
            if (this.beneficiario.numunicipio.name != 'Itajaí' && this.beneficiario.numunicipio.name != 'Navegantes') {
                this.$toast.error({
                    title: "Erro",
                    message:
                        "CEP do beneficário inválido! Município deve ser Itajaí ou Navegantes"
                });
                return false;
            }
            return true;
        },
        async saveBeneficiarioForm() {
            this.isFormValid();
            if (this.hasErrors || this.hasFiliacaoErrors) {
                this.$toast.error({
                    title: "Erro",
                    message: this.hasFiliacaoErrors ? "É necessário atualizar as informações de filiação!" : "Verifique os campos obrigatórios!"
                });
            } else {
                const isCepValid = this.validateBeneficiarioCEP();
                if (isCepValid) {
                    this.handleEditBeneficiario();
                }
            }
        },
        formatDate(date) {
            const [day, month, year] = date.split("/");
            return `${year}-${month}-${day}`;
        },
        validateBirthDateOnInput(value) {
            let originalValue = value.slice(0, -1);
            let valid = true;

            // previne que a data seja maior que 10 caracteres
            if (value.length > 10) {
                valid = false;
            }

            // valida se foi digitado alfanumérico
            if (isNaN(value.charAt(value.length - 1)) && value.charAt(value.length - 1) !== '/') {
                valid = false;
            }

            // valida primeiro digito
            if (value.length == 1) {
                valid = new RegExp(/^[0-3]$/).test(value.charAt(0));
            }

            // valida segundo digito
            if (value.length == 2) {
                if (value.charAt(0) == '3') {
                    valid = new RegExp(/^[0-1]$/).test(value.charAt(1));
                } else if (value.charAt(0) == '0') {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(1));
                } else {
                    valid = new RegExp(/^[0-9]$/).test(value.charAt(1));
                }
                value += '/';
            }

            // valida terceiro digito
            if (value.length == 4) {
                valid = new RegExp(/^[0-1]$/).test(value.charAt(3));
            }

            // valida quarto digito
            if (value.length == 5) {
                if (value.charAt(3) == '1') {
                    valid = new RegExp(/^[0-2]$/).test(value.charAt(4));
                } else {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(4));
                }
                value += '/';
            }

            if (valid) {
                this.beneficiarioForm.dtnascimento = value;
                if (this.beneficiarioForm.dtnascimento && this.beneficiarioForm.dtnascimento.length === 10) {
                    const parsedDate = parse(this.beneficiario.dtnascimento, 'dd/MM/yyyy', new Date());
                    this.validBirthDate = isValid(parsedDate) && this.checkAtLeast1YearOld(parsedDate);
                }
            } else {
                this.beneficiarioForm.dtnascimento = originalValue;
            }
        },
        checkMajority(parsedDate) {
            var today = new Date();
            var age = today.getFullYear() - parsedDate.getFullYear();
            var m = today.getMonth() - parsedDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < parsedDate.getDate())) {
                age--;
            }
            return age;
        },
        checkAtLeast1YearOld(parsedDate) {
            var today = new Date();
            var age = today.getFullYear() - parsedDate.getFullYear();
            var m = today.getMonth() - parsedDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < parsedDate.getDate())) {
                age--;
            }
            return age >= 1;
        },
        isFormValid() {
            const {
                document,
                nmnome,
                dtnascimento,
                cep,
                street,
                // number,
                district
            } = this.beneficiarioForm;
            this.hasEstudanteErrors = false;
            this.hasEstagiarioErrors = false;
            this.hasTrabalhadorErrors = false;

            // additionalConditions define se os campos de filiação são obrigatórios se idade < 18
            this.additionalConditions = false;
            let additionalConditionsSatisfied = true;

            let dtNasc = this.isBeneficiarioEdit ? dtnascimento : this.beneficiario.dtnascimento;

            if (this.beneficiarioForm.dtnascimento && this.beneficiarioForm.dtnascimento.length === 8) {
                this.validBirthDate = false;
            }

            if (dtNasc && dtNasc.length === 10) {
                const parsedDate = parse(dtNasc, 'dd/MM/yyyy', new Date());
                if (!this.checkAtLeast1YearOld(parsedDate)) {
                    this.validBirthDate = false;
                }
                this.additionalConditions = this.checkMajority(parsedDate) < 18 ? true : false;
                additionalConditionsSatisfied = false;
            }

            this.beneficiarioForm.filiacao.forEach((filiacao, index) => {
                // define valores para os campos de filiação independente se é edição ou não
                let doc = this.isFiliacaoEdit[index] ? filiacao.document : this.beneficiario.filiacao[index].document;
                let cep = this.isFiliacaoEdit[index] ? filiacao.cep : this.beneficiario.filiacao[index].cep;
                let nome = this.isFiliacaoEdit[index] ? filiacao.nmnome : this.beneficiario.filiacao[index].nmnome;

                // verifica se existem condições adicionais e se elas foram satisfeitas
                if (this.additionalConditions && !additionalConditionsSatisfied) {
                    // condições adicionais são satisfeitas se os campos de filiação estão preenchidos
                    additionalConditionsSatisfied = doc && cep ? true : false;
                }

                if (
                    // se possui condições adicionais e se elas não estão satisfeitas ou
                    (this.additionalConditions && !additionalConditionsSatisfied) ||
                    //  se o nome está vazio
                    this.isNullOrEmpty(nome) ||
                    // se o cpf preenchido e o cep vazio ou
                    (this.isNullOrEmpty(doc) && !this.isNullOrEmpty(cep)) ||
                    // se o cpf vazio e o cep preenchido
                    (!this.isNullOrEmpty(doc) && this.isNullOrEmpty(cep))
                ) {
                    this.hasFiliacaoErrors = true;
                    this.isFiliacaoEdit[0] = true;
                } else {
                    this.hasFiliacaoErrors = false;
                }
            });
            if (Number(this.beneficiario.tpbeneficiario) == TipoBeneficiario.ESTUDANTE) {
                const {
                    curso,
                    nuseriecurso,
                    tpcurso,
                    nminstituicao,
                    document
                } = this.beneficiarioEstudanteForm;
                if (tpcurso > 2 && curso === undefined) {
                    this.hasEstudanteErrors = true;
                    this.beneficiarioEstudanteForm.hasErrors = true;
                }
                if (
                    document === undefined ||
                    nuseriecurso === undefined ||
                    nminstituicao === undefined ||
                    tpcurso === undefined
                ) {
                    this.hasEstudanteErrors = true;
                    this.beneficiarioEstudanteForm.hasErrors = true;
                } else {
                    this.hasEstudanteErrors = false;
                }
            } else if (Number(this.beneficiario.tpbeneficiario) == TipoBeneficiario.TRABALHADOR) {
                const {
                    profissao,
                    dtadmissao,
                    nminstituicao,
                    document,
                    vlsalario,
                    isValidAdmissionDate,
                } = this.beneficiarioTrabalhadorForm;
                if (
                    document === undefined ||
                    dtadmissao === undefined ||
                    profissao === undefined ||
                    nminstituicao === undefined ||
                    vlsalario === 0 ||
                    !isValidAdmissionDate
                ) {
                    this.hasTrabalhadorErrors = true;
                    this.beneficiarioTrabalhadorForm.hasErrors = true;
                } else {
                    this.hasTrabalhadorErrors = false;
                }
            } else if (Number(this.beneficiario.tpbeneficiario) == TipoBeneficiario.ESTAGIARIO) {
                const {
                    dtencerramento,
                    dtadmissao,
                    nminstituicao,
                    document,
                    isValidAdmissionDate,
                    isValidClosureDate
                } = this.beneficiarioEstagiarioForm;

                if (
                    dtencerramento === undefined ||
                    document === undefined ||
                    nminstituicao === undefined ||
                    dtadmissao === undefined ||
                    !isValidAdmissionDate ||
                    !isValidClosureDate
                ) {
                    this.hasEstagiarioErrors = true;
                    this.beneficiarioEstagiarioForm.hasErrors = true;
                } else {
                    this.hasEstagiarioErrors = false;
                }
            } else {
                this.hasFiliacaoErrors = true;
            }

            if (this.hasEstudanteErrors ||
                this.hasTrabalhadorErrors ||
                this.hasEstagiarioErrors) {
                window.location.href = '#ac'
            }
            if (
                this.hasEstudanteErrors ||
                this.hasTrabalhadorErrors ||
                this.hasEstagiarioErrors ||
                this.hasFiliacaoErrors ||
                this.isNullOrEmpty(document) ||
                this.isNullOrEmpty(nmnome) ||
                this.isNullOrEmpty(cep) ||
                this.isNullOrEmpty(street) ||
                // this.isNullOrEmpty(number) ||
                this.isNullOrEmpty(district) ||
                this.isNullOrEmpty(dtnascimento) ||
                !this.validBirthDate
            ) {
                this.hasErrors = true;
            } else {
                this.hasErrors = false;
            }
        },
        isNullOrEmpty(value) {
            return value === null || value === "";
        },
        onEditBeneficiarioFiliacaoClick(index) {
            if (this.beneficiarioForm.filiacao[index].document != '' &&
                this.beneficiarioForm.filiacao[index].cep != '') {
                this.isFiliacaoContatoEdit = true;
            } else {
                this.$toast.error({
                    message: "Os campos CPF e CEP da filiação são obrigatórios."
                });
            }
        },
        onEditBeneficiarioClick(secaoEdit, index = undefined) {
            // this.beneficiarioForm = JSON.parse(
            //     JSON.stringify(this.beneficiario)
            // );
            //this.contatosInsituicaoForm = JSON.parse(
            //    JSON.stringify(this.contatosInsituicao)
            //);
            // this.contatosFiliacaoForm = JSON.parse(
            //     JSON.stringify(
            //         this.beneficiario.filiacao.map(
            //             filiacao => filiacao.contatos
            //         )
            //     )
            // );
            this.enderecoInstituicaoForm = { ...this.enderecoInstituicao };
            this.contatosBeneficiarioForm = JSON.parse(
                JSON.stringify(this.beneficiario.contatos)
            );

            switch (secaoEdit) {
                case "contatos":
                    this.isContatoEdit = true;
                    break;
                case "filiacao":
                    this.isFiliacaoEdit[index] = true;
                    break;
                case "filiacaoContato":
                    this.isFiliacaoContatoEdit = true;
                    break;
                case "trabalhadorOuEstudante":
                    this.isTrabalhadorOuEstudanteEdit = true;
                    break;
                case "contatoInstituicaoEdit":
                    this.isContatoInstituicaoEdit = true;
                    break;
                case "observacaoEdit":
                    this.isObservacaoEdit = true;
                    break;
                default:
                    this.isBeneficiarioEdit = true;
            }

            // switch (Number(this.beneficiario.tpbeneficiario)) {
            //     case TipoBeneficiario.ESTUDANTE:
            //         this.beneficiarioEstudanteForm = {
            //             ...this.beneficiarioEstudante
            //         };
            //         break;
            //     case TipoBeneficiario.TRABALHADOR:
            //         this.beneficiarioTrabalhadorForm = {
            //             ...this.beneficiarioTrabalhador
            //         };
            //         break;
            //     case TipoBeneficiario.ESTAGIARIO:
            //         this.beneficiarioEstagiarioForm = {
            //             ...this.beneficiarioEstagiario
            //         };
            //         break;
            // }
        },
        cancelEditBeneficiario() {
            if (this.beneficiario.tpbeneficiario.toString() != this.beneficiarioForm.tpbeneficiario_inicial) {
                this.beneficiario.tpbeneficiario = this.beneficiarioForm.tpbeneficiario_inicial
            }
            this.isTrabalhadorOuEstudanteEdit = false;
            this.isBeneficiarioEdit = false;
            this.isObservacaoEdit = false;
        },
        cancelEditContato() {
            this.isContatoEdit = false;
        },
        cancelEditFiliacao(index = undefined) {
            Vue.set(this.isFiliacaoEdit, index, false);
        },
        cancelEditFiliacaoContato() {
            this.isFiliacaoContatoEdit = false;
        },
        cancelEditTrabalhadorOuEstudante() {
            this.isTrabalhadorOuEstudanteEdit = false;
        },
        cancelEditContatoInstituicao() {
            this.isContatoInstituicaoEdit = false;
        },
        getSituation(situationId) {
            if (situationId) {
                return this.situations.find(item => item.id === situationId);
            }
        },
        getTpCurso(tpcurso) {
            if (tpcurso) {
                return this.tipoCursos.find(
                    item => Number(item.id) === Number(tpcurso)
                );
            }
        },
        getBeneficiarioType(type) {
            if (type) {
                switch (Number(type)) {
                    case TipoBeneficiario.ESTAGIARIO:
                        return "Estagiário";
                    case TipoBeneficiario.ESTUDANTE:
                        return "Estudante";
                    case TipoBeneficiario.TRABALHADOR:
                        return "Trabalhador";
                }
            }
            return "-";
        },
        toggleObsPopup() {
            this.mostrarPopupPendencia = !this.mostrarPopupPendencia;
        },
        editObs() {
            this.mostrarPopupPendencia = false;
            this.isBeneficiarioEdit = true;
            this.postDisablePending();
            this.beneficiario.idsituacao = 2;
            this.$router.go(0);
        },
        abrirEntregaPasses() {
            if (this.beneficiarioForm.tpbeneficiario == '0') {
                this.$toast.error({ message: "Necessário informar o tipo de beneficiário." });
                return false;
            }
            if (this.beneficiarioForm.controle_registro.length == 0) {
                this.$toast.error({ message: "Necessário realizar a entrega de documentos." });
                return false;
            }


            const { idsituacao } = this.beneficiario;
            const params = {
                id: this.id,
                screenType: "passes"
            };

            switch (idsituacao) {
                case 1:
                case 2:
                    {
                        this.$router.push({
                            name: "spl-navegantes.documents",
                            params
                        });
                    }
                    break;
                case 5:
                    {
                        this.modalEntregaDocumentosData.title =
                            "Existe alguma pendência no registro desse beneficiário, verifique qual é a partir do quadro acima";
                        this.$refs.modalEntregaDocumentos.showModal();
                    }
                    break;
                case 4:
                    {
                        this.modalEntregaDocumentosData.title =
                            "O registro do beneficiário está vencido, você precisa solicitar toda a documentação para atualizar o cadastro";
                        this.$refs.modalEntregaDocumentos.showModal();
                    }
                    break;
                case 6:
                    {
                        this.modalEntregaDocumentosData.title =
                            "O registro deste beneficiário está cancelado, ele não poderá mais receber o benefício";
                        this.$refs.modalEntregaDocumentos.showModal();
                    }
                    break;
                case 7:
                    {
                        this.$router.push({
                            name: "spl-navegantes.documents",
                            params
                        });
                    }
                    break;
                case 3:
                    {
                        this.$router.push({
                            name: "spl-navegantes.documents",
                            params
                        });
                    }
                    break;
            }
        },
        abrirEntregaDocumentos() {
            if (this.beneficiarioForm.tpbeneficiario == '0') {
                this.$toast.error({ message: "Necessário informar o tipo de beneficiário." });
                return false;
            }
            const { idsituacao } = this.beneficiario;
            const params = {
                id: this.id,
                screenType: "document"
            };

            switch (idsituacao) {
                case 1:
                    {
                        this.modalEntregaDocumentosData.title =
                            "O cadastro deste beneficiário ainda não foi concluído, favor finalizar o cadastro";
                        this.$refs.modalEntregaDocumentos.showModal();
                    }
                    break;
                case 3 || 2:
                    {
                        const dtexpiracao = new Date(
                            this.beneficiario.controle_registro.dtexpiracao
                        );
                        const dtnow = new Date();
                        const intervalo = dtexpiracao - dtnow;
                        const intervaloDias = Math.ceil(
                            intervalo / (1000 * 60 * 60 * 24)
                        );

                        if (intervaloDias < 31) {
                            this.$router.push({
                                name: "spl-navegantes.documents",
                                params
                            });
                        } else {
                            this.modalEntregaDocumentosData.title =
                                "O registro deste beneficiário não está para vencer, você deseja atualizar o registro?";
                            this.modalEntregaDocumentosData.action =
                                "ABRIR_DOCUMENTOS";
                            this.$refs.modalEntregaDocumentos.showModal();
                        }
                    }
                    break;
                case 5:
                    {
                        this.modalEntregaDocumentosData.title =
                            "Existe alguma pendência no registro desse beneficiário, verifique qual é a partir do quadro acima";
                        this.$refs.modalEntregaDocumentos.showModal();
                    }
                    break;
                case 8:
                    {
                        this.$router.push({
                            name: "spl-navegantes.documents",
                            params
                        });
                    }
                    break;
                case 6:
                    {
                        this.modalEntregaDocumentosData.title =
                            "O registro deste beneficiário está cancelado, ele não poderá mais receber o benefício";
                        this.$refs.modalEntregaDocumentos.showModal();
                    }
                    break;
                // case 7:
                //     {
                //         this.modalEntregaDocumentosData.title =
                //             "O cadastro deste beneficiário ainda não foi concluído, favor finalizar o cadastro";
                //         this.$refs.modalEntregaDocumentos.showModal();
                //     }
                //     break;
                default: {
                    this.$router.push({
                        name: "spl-navegantes.documents",
                        params
                    });
                }
            }
        },
        onModalEntregaDocumentosClose() {
            const action = this.modalEntregaDocumentosData.action;

            if (action == "ABRIR_DOCUMENTOS")
                this.$router.push({
                    name: "spl-navegantes.documents",
                    params: { id: this.id }
                });
        },
        cityBeneficiarioSelected(city) {
            this.beneficiario.numunicipio = city;
        },
        cityFiliacaoSelected(city) {
            this.beneficiarioForm.filiacao[0].city_id = city;
        },
        tipoBeneficiariosSelected() {
            //if(beneficiario.tpbeneficiario)
            this.isTrabalhadorOuEstudanteEdit = true;
        }
    },
    setValidAdmissionDate(value) {
        this.isValidAdmissionDate = value;
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getBeneficiario();
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>

<template>
    <div class="w-full bg-white">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-8" :crumbs="crumbs" />

            <div v-if="obs_pendencia" class="relative">
                <!-- <div class="content-pendencia mb-2 text-uppercase p-2">
                    <div class="title flex flex-row justify-between items-center">
                        <span class="flex flex-row items-center text-base uppercase text-red-600 font-bold">
                            <Icon icon="exclamation" class="h-4 mr-1 fill-current" />
                            Informação de pendência
                        </span>
                        <span @click="toggleObsPopup">
                            <Icon icon="arrowDown" class="cursor-pointer h-4 mr-1 fill-current" />
                        </span>
                    </div>
                </div> -->

                <div v-if="mostrarPopupPendencia"
                    class="content-pendencia expandido mb-2 text-uppercase p-2 absolute shadow-lg">
                    <div class="title flex flex-row justify-between items-center">
                        <span class="flex flex-row items-center text-base uppercase text-red-600 font-bold">
                            <Icon icon="exclamation" class="h-4 mr-1 fill-current" />
                            Informação de pendência
                        </span>
                    </div>
                    <div class="p-4">
                        <span class="text-base uppercase font-bold">
                            {{ obs_pendencia }}
                        </span>
                    </div>
                    <div class="flex flex-row justify-between items-end">
                        <div class="px-4">
                            <span class="text-base text-gray-800 text-uppercase">
                                Você deseja retirar essa pendência?
                            </span>
                            <div class="flex flex-row justify-start">
                                <button type="button"
                                    class="flex items-center justify-center form-submit md:w-auto px-8"
                                    @click="toggleObsPopup" style="background: #e2e8f0;color: #0C599F">
                                    Não
                                </button>
                                <button type="button" @click="editObs"
                                    class="ml-2 flex items-center justify-center form-submit md:w-auto px-8">
                                    Sim
                                </button>
                            </div>
                        </div>
                        <span @click="toggleObsPopup">
                            <Icon icon="arrowUp" class="cursor-pointer h-4 mr-1 fill-current" />
                        </span>
                    </div>
                </div>
            </div>

            <div class="bg-gray-light lg:max-w-full my-4" :class="{ 'blur-content': mostrarPopupPendencia }">
                <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Controle de Registro
                    </span>
                    <button v-if="!getBeneficiarioLoading && !isObservacaoEdit"
                        @click="onEditBeneficiarioClick('observacaoEdit')"
                        class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                        <div class="flex text-blue-base items-center justify-center">
                            Editar
                        </div>
                    </button>
                    <div v-if="!getBeneficiarioLoading && isObservacaoEdit" class="flex gap-3">
                        <button @click="cancelEditBeneficiario"
                            class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                            <div class="flex text-red-400 items-center justify-center">
                                Cancelar
                            </div>
                        </button>
                        <button @click.prevent="$refs.modal.showModal()"
                            class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                            <div class="flex text-green-500 items-center justify-center">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>
                <template v-if="getBeneficiarioLoading">
                    <div class="px-4">
                        Carregando...
                    </div>
                </template>
                <template v-else>
                    <div class="grid gap-5 grid-cols-1 md:grid-cols-12 px-3 pb-6">
                        <div class="col-span-2">
                            <span class="text-sm form-label text-gray-dark">
                                DT. Expiração
                            </span>
                            <div class="text-red-500">
                                {{
                                    beneficiario.controle_registro.length != 0 ?
                                        format(
                                            new Date(
                                                beneficiario.controle_registro.dtexpiracao
                                            ),
                                            "dd/MM/yyyy"
                                        )
                                        : '-'
                                }}
                            </div>
                        </div>

                        <div class="col-span-2">
                            <span class="text-sm form-label text-gray-dark">
                                Situação
                            </span>
                            <div style="display: flex; justify-content: space-between;">
                                <div :style="{
                                    color: beneficiario.idsituacao ? getSituation(beneficiario.idsituacao).color : ''
                                }
                                    ">
                                    {{
                                        beneficiario.controle_registro.length != 0 ?
                                            getSituation(beneficiario.controle_registro.idsituacao).label : '-'
                                    }}
                                </div>
                                <div v-if="beneficiario.idsituacao == 5" class="text-red-500 cursor-pointer"
                                    style=" float: left; width: 50%; padding: 5px;" @click="toggleObsPopup">
                                    <Icon icon="exclamation" class="h-4 mr-1 fill-current" />
                                </div>
                            </div>

                        </div>

                        <div class="col-span-7">
                            <span class="text-sm form-label text-gray-dark">
                                Observação
                            </span>
                            <div class="w-full" v-if="isObservacaoEdit">
                                <textarea v-model="obs" maxlength="255" class="py-2 rounded form-input" row="5"
                                    style="max-height: 80px; min-height: 80px;"
                                    placeholder="Escreva aqui sua observação"></textarea>
                            </div>
                            <div v-else>
                                <span class="text-sm form-label text-gray-dark">
                                    {{ beneficiario.controle_registro.length != 0 ? obs : '-' }}
                                </span>
                            </div>
                        </div>
                        <div class="col-span-12">
                            <div class="flex flex-row justify-start items-center">
                                Opções

                                <button @click="abrirEntregaDocumentos()"
                                    class="flex items-center justify-center form-submit py-2 ml-3 md:w-auto"
                                    style="background: #e2e8f0; border: solid 1px #0C599F; color: #0C599F">
                                    Entrega de documentos
                                </button>

                                <button @click="abrirEntregaPasses()"
                                    class="flex items-center justify-center form-submit py-2 ml-3 md:w-auto">
                                    Entrega de passes
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Informações Pessoais
                    </span>
                    <button v-if="!getBeneficiarioLoading && !isBeneficiarioEdit" @click="onEditBeneficiarioClick"
                        class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                        <div class="flex text-blue-base items-center justify-center">
                            Editar
                        </div>
                    </button>
                    <div v-if="!getBeneficiarioLoading && isBeneficiarioEdit" class="flex gap-3">
                        <button @click="cancelEditBeneficiario"
                            class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                            <div class="flex text-red-400 items-center justify-center">
                                Cancelar
                            </div>
                        </button>
                        <button @click.prevent="$refs.modal.showModal()"
                            class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                            <div class="flex text-green-500 items-center justify-center">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>

                <template v-if="getBeneficiarioLoading">
                    <div class="px-4">
                        Carregando...
                    </div>
                </template>
                <template v-else>
                    <div class="grid gap-5 grid-cols-1 md:grid-cols-12  px-3">
                        <div class="col-span-3">
                            <span class="text-sm form-label text-gray-dark">
                                CPF
                            </span>
                            <div v-if="isBeneficiarioEdit">
                                <TheMask v-model="beneficiarioForm.document" :mask="['###.###.###-##']" type="text"
                                    class="h-auto py-2 rounded form-input mb-0" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioForm.document
                                    }" />
                                <div v-if="
                                    hasErrors && !beneficiarioForm.document
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div v-else>
                                {{ beneficiario.document }}
                            </div>
                        </div>
                        <div class="col-span-6">
                            <span class="text-sm form-label text-gray-dark">
                                Nome
                            </span>

                            <div v-if="isBeneficiarioEdit">
                                <input v-model="beneficiarioForm.nmnome" type="text"
                                    class="h-auto py-2 rounded form-input mb-0" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioForm.nmnome
                                    }" />
                                <div v-if="hasErrors && !beneficiarioForm.nmnome"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div v-else>
                                {{ beneficiario.nmnome }}
                            </div>
                        </div>
                        <div class="col-span-3">
                            <span class="text-sm form-label text-gray-dark">
                                Data de nascimento
                            </span>
                            <div v-if="isBeneficiarioEdit">
                                <input v-model="beneficiarioForm.dtnascimento" type="text"
                                    class="h-auto py-2 rounded form-input" :class="{
                                        'bg-red-100 border-red-400': hasErrors && (!beneficiarioForm.dtnascimento || !validBirthDate),
                                    }" required @input="$event => validateBirthDateOnInput($event.target.value)" />
                                <div v-if="!validBirthDate || beneficiarioForm.dtnascimento.length === 8"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Data inválida
                                </div>

                                <div v-if="
                                    hasErrors &&
                                    !beneficiarioForm.dtnascimento
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div v-else>
                                {{ beneficiario.dtnascimento }}
                            </div>
                        </div>
                        <div class="col-span-3">
                            <span class="text-sm form-label text-gray-dark">
                                Matrícula
                            </span>
                            <div>
                                {{ id }}
                            </div>
                        </div>
                        <div class="col-span-3">
                            <span class="text-sm form-label text-gray-dark">
                                Tipo de beneficiário
                            </span>
                            <div class="" v-if="isBeneficiarioEdit">
                                <Select :key="beneficiario.tpbeneficiario" id="status" class="w-full"
                                    selectClass="w-full form-select filter-select " :options="tiposBeneficiario"
                                    :selected.sync="beneficiario.tpbeneficiario" @changed="tipoBeneficiariosSelected" />
                                <div v-if="hasErrors && beneficiario.tpbeneficiario == 0"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </div>


                            <div v-else>
                                {{
                                    getBeneficiarioType(
                                        beneficiario.tpbeneficiario
                                    )
                                }}
                            </div>
                        </div>
                        <div class="col-span-6">

                        </div>
                        <div class="col-span-3">
                            <span class="text-sm form-label text-gray-dark">
                                CEP
                            </span>
                            <div v-if="isBeneficiarioEdit">
                                <TheMask v-model="beneficiarioForm.cep" :mask="['##.###-###']"
                                    class="h-auto py-2 rounded form-input mb-0" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors && !beneficiarioForm.cep
                                    }" @input="onCepChangeBeneficiario" required />
                                <div v-if="hasErrors && !beneficiarioForm.cep"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div v-else>
                                {{ mask(beneficiario.cep, "#####-###") }}
                            </div>
                        </div>
                        <div class="col-span-6">
                            <span class="text-sm form-label text-gray-dark">
                                Endereço
                            </span>
                            <div v-if="isBeneficiarioEdit">
                                <input v-model="beneficiarioForm.street" type="text"
                                    class="h-auto py-2 rounded form-input mb-0" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioForm.street
                                    }" />
                                <div v-if="hasErrors && !beneficiarioForm.street"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div v-else>
                                {{ beneficiario.street }}
                            </div>
                        </div>
                        <div class="col-span-1">
                            <span class="text-sm form-label text-gray-dark">
                                Número
                            </span>
                            <div v-if="isBeneficiarioEdit">
                                <input v-model="beneficiarioForm.number" type="number"
                                    class="h-auto py-2 rounded form-input mb-0" />
                                <!-- <div
                                    v-if="hasErrors && !beneficiarioForm.number"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                >
                                    Campo obrigatório
                                </div> -->
                            </div>
                            <div v-else>
                                {{ beneficiario.number }}
                            </div>
                        </div>
                        <div class="col-span-2">
                            <span class="text-sm form-label text-gray-dark">
                                Bairro
                            </span>
                            <div v-if="isBeneficiarioEdit">
                                <input v-model="beneficiarioForm.district" type="text"
                                    class="h-auto py-2 rounded form-input mb-0" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioForm.district
                                    }" />
                                <div v-if="
                                    hasErrors && !beneficiarioForm.district
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div v-else>
                                {{ beneficiario.district }}
                            </div>
                        </div>
                        <div class="col-span-3">
                            <span class="text-sm form-label text-gray-dark">
                                Complemento
                            </span>
                            <div v-if="isBeneficiarioEdit">
                                <input v-model="beneficiarioForm.complement" type="text"
                                    class="h-auto py-2 rounded form-input mb-0" />
                            </div>
                            <div v-else>
                                {{ beneficiario.complement }}
                            </div>
                        </div>
                        <div class="col-span-6 ">
                            <span class="text-sm form-label text-gray-dark">
                                Cidade/estado
                            </span>
                            <div v-if="isBeneficiarioEdit">
                                <search-select :selected.sync="beneficiarioForm.numunicipio" :search-keys="['name']"
                                    :search-remote="true" :lazy-load="true"
                                    options-url="/api/registry/location?take=50&paginate=false" option-key="id"
                                    :option-value="city =>
                                        city.name +
                                        ' / ' +
                                        city.state.initials
                                        " field-class="py-2" name="city" placeholder="Selecione uma cidade..."
                                    @changed="cityBeneficiarioSelected" />
                            </div>
                            <div v-else>
                                {{
                                    beneficiario.numunicipio.name +
                                    " / " +
                                    beneficiario.numunicipio.state.initials
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                        <span class="mt-2 text-base uppercase text-blue-base">
                            Contato
                        </span>
                        <button v-if="!getBeneficiarioLoading && !isContatoEdit"
                            @click="onEditBeneficiarioClick('contatos')"
                            class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                            <div class="flex text-blue-base items-center justify-center">
                                Editar
                            </div>
                        </button>
                        <div v-if="!getBeneficiarioLoading && isContatoEdit" class="flex gap-3">
                            <button @click="cancelEditContato"
                                class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                                <div class="flex text-red-400 items-center justify-center">
                                    Cancelar
                                </div>
                            </button>
                            <button @click.prevent="$refs.modal.showModal()"
                                class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                                <div class="flex text-green-500 items-center justify-center">
                                    Salvar
                                </div>
                            </button>
                        </div>
                    </div>
                    <ContactsList :emails="beneficiario.contatos.emails" :phones="beneficiario.contatos.phones"
                        :emailsForm="contatosBeneficiarioForm.emails" :phonesForm="contatosBeneficiarioForm.phones"
                        :readonly="!isContatoEdit"></ContactsList>

                    <div v-for="(filiacao, index) in beneficiario.filiacao" :key="index">
                        <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                            <span class="mt-2 text-base uppercase text-blue-base">
                                Filiação
                            </span>

                            <button v-if="!getBeneficiarioLoading && !isFiliacaoEdit[index]"
                                @click="onEditBeneficiarioClick('filiacao', index)"
                                class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                                <div class="flex text-blue-base items-center justify-center">
                                    Editar
                                </div>
                            </button>
                            <div v-if="!getBeneficiarioLoading && isFiliacaoEdit[index]" class="flex gap-3">
                                <button @click="cancelEditFiliacao(index)"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                                    <div class="flex text-red-400 items-center justify-center">
                                        Cancelar
                                    </div>
                                </button>
                                <button @click.prevent="$refs.modal.showModal()"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                                    <div class="flex text-green-500 items-center justify-center">
                                        Salvar
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div class="grid gap-5 grid-cols-1 md:grid-cols-12  px-3">
                            <div class="col-span-3">
                                <span class="text-sm form-label text-gray-dark">
                                    CPF {{ hasAdditionalConditions ? '*' : '' }}
                                </span>
                                <div v-if="isFiliacaoEdit[index]">
                                    <TheMask v-model="beneficiarioForm.filiacao[index].document"
                                        :mask="['###.###.###-##']" type="text"
                                        @input="onCpfChangeFiliacao(index, $event)"
                                        class="h-auto py-2 rounded form-input mb-0"
                                        :class="{'bg-red-100 border-red-400': hasErrors &&
                                            !beneficiarioForm.filiacao[index].document && beneficiarioForm.filiacao[index].cep}" />

                                    <div v-if="hasErrors &&
                                        !beneficiarioForm.filiacao[index].document && beneficiarioForm.filiacao[index].cep"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </div>
                                <span v-else>
                                    {{ mask(filiacao.document, "###.###.###-##") }}
                                </span>

                            </div>
                            <div class="col-span-6">
                                <span class="text-sm form-label text-gray-dark">
                                    Nome
                                </span>
                                <div v-if="isFiliacaoEdit[index]">
                                    <input v-model="beneficiarioForm.filiacao[index].nmnome" type="text"
                                        class="h-auto py-2 rounded form-input mb-0" :class="{
                                            'bg-red-100 border-red-400':
                                                hasErrors && !beneficiarioForm.filiacao[index].nmnome
                                            // || ((beneficiarioForm.filiacao[index].document == undefined || beneficiarioForm.filiacao[index].document == '') &&
                                            //     (beneficiarioForm.filiacao[index].cep != undefined && beneficiarioForm.filiacao[index].cep != ''))
                                        }" />
                                    <div v-if="(hasErrors && !beneficiarioForm.filiacao[index].nmnome)
                                        // || ((beneficiarioForm.filiacao[index].document == undefined ||
                                        //         beneficiarioForm.filiacao[index].document == '') && (beneficiarioForm.filiacao[index].cep != undefined && beneficiarioForm.filiacao[index].cep != ''))
                                    " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </div>
                                <span v-else>
                                    {{ filiacao.nmnome }}
                                </span>
                            </div>
                            <div class="col-span-3">
                                <span class="text-sm form-label text-gray-dark">
                                    Parentesco
                                </span>
                                <div v-if="isFiliacaoEdit[index]">
                                    <Select :key="beneficiarioForm.filiacao[index]
                                        .tpparentesco
                                        " id="status" :class="{
                                            'w-full bg-red-100 border-red-400':
                                                hasErrors &&
                                                !beneficiarioForm.filiacao[
                                                    index
                                                ].nmnome || ((beneficiarioForm.filiacao[index].document == undefined || beneficiarioForm.filiacao[index].document == '') &&
                                                    (beneficiarioForm.filiacao[index].cep != undefined && beneficiarioForm.filiacao[index].cep != ''))
                                        }" selectClass="w-full form-select filter-select " :options="filiacaoOptions"
                                        :selected.sync="beneficiarioForm.filiacao[index]
                                            .tpparentesco
                                            " />
                                    <div v-if="(hasErrors &&
                                        !beneficiarioForm.filiacao[
                                            index
                                        ].tpparentesco) || ((beneficiarioForm.filiacao[index].document == undefined || beneficiarioForm.filiacao[index].document == '') &&
                                            (beneficiarioForm.filiacao[index].cep != undefined && beneficiarioForm.filiacao[index].cep != ''))"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </div>
                                <span v-else>
                                    {{ getParentesco(filiacao.tpparentesco) }}
                                </span>
                            </div>
                            <div class="col-span-3">
                                <span class="text-sm form-label text-gray-dark">
                                    CEP
                                </span>

                                <div v-if="isFiliacaoEdit[index]">
                                    <TheMask v-model="beneficiarioForm.filiacao[index].cep" :mask="['##.###-###']"
                                        class="h-auto py-2 rounded form-input mb-0" 
                                        :class="{'bg-red-100 border-red-400': hasErrors &&
                                            beneficiarioForm.filiacao[index].document && !beneficiarioForm.filiacao[index].cep}"
                                        @input="onCepChangeFiliacao(index, $event)" required />

                                    <div v-if="hasErrors &&
                                        beneficiarioForm.filiacao[index].document && !beneficiarioForm.filiacao[index].cep"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </div>
                                <div v-else>
                                    {{ filiacao.cep ? mask(filiacao.cep, "#####-###") : "" }}
                                </div>
                            </div>
                            <div class="col-span-6">
                                <span class="text-sm form-label text-gray-dark">
                                    Endereço
                                </span>
                                <div v-if="isFiliacaoEdit[index]">
                                    <input v-model="beneficiarioForm.filiacao[index].street
                                        " disabled type="text" class="h-auto py-2 rounded form-input mb-0" />
                                </div>
                                <div v-else>
                                    {{ filiacao.street }}
                                </div>
                            </div>
                            <div class="col-span-1">
                                <span class="text-sm form-label text-gray-dark">
                                    Número
                                </span>
                                <div v-if="isFiliacaoEdit[index]">
                                    <input v-model="beneficiarioForm.filiacao[index]
                                        .number
                                        " type="number" class="h-auto py-2 rounded form-input mb-0" />
                                    <!-- <div
                                        v-if="
                                            hasErrors &&
                                                !beneficiarioForm.filiacao[
                                                    index
                                                ].number
                                        "
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                    >
                                        Campo obrigatório
                                    </div> -->
                                </div>
                                <div v-else>
                                    {{ filiacao.number }}
                                </div>
                            </div>
                            <div class="col-span-2">
                                <span class="text-sm form-label text-gray-dark">
                                    Bairro
                                </span>
                                <div v-if="isFiliacaoEdit[index]">
                                    <input v-model="beneficiarioForm.filiacao[index]
                                        .district
                                        " type="text" class="h-auto py-2 rounded form-input mb-0" disabled :class="{
                                            'bg-red-100 border-red-400':
                                                (isNullOrEmpty(beneficiarioForm.filiacao[index].district) &&
                                                    beneficiarioForm.filiacao[index].contatos.emails.length > 0 &&
                                                    beneficiarioForm.filiacao[index].contatos.emails[0].contact !== '' &&
                                                    isNullOrEmpty(beneficiarioForm.filiacao[index].contatos.emails[0].id)) ||
                                                (isNullOrEmpty(beneficiarioForm.filiacao[index].district) &&
                                                    beneficiarioForm.filiacao[index].contatos.phones.length > 0 &&
                                                    beneficiarioForm.filiacao[index].contatos.phones[0].contact !== '' &&
                                                    isNullOrEmpty(beneficiarioForm.filiacao[index].contatos.phones[0].id))
                                        }" />
                                    <div v-if="(isNullOrEmpty(beneficiarioForm.filiacao[index].district) &&
                                        beneficiarioForm.filiacao[index].contatos.emails.length > 0 &&
                                        beneficiarioForm.filiacao[index].contatos.emails[0].contact !== '' &&
                                        isNullOrEmpty(beneficiarioForm.filiacao[index].contatos.emails[0].id)) ||
                                        (isNullOrEmpty(beneficiarioForm.filiacao[index].district) &&
                                            beneficiarioForm.filiacao[index].contatos.phones.length > 0 &&
                                            beneficiarioForm.filiacao[index].contatos.phones[0].contact !== '' &&
                                            isNullOrEmpty(beneficiarioForm.filiacao[index].contatos.phones[0].id))"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </div>
                                <div v-else>
                                    {{ filiacao.district }}
                                </div>
                            </div>
                            <div class="col-span-3">
                                <span class="text-sm form-label text-gray-dark">
                                    Complemento
                                </span>
                                <div v-if="isFiliacaoEdit[index]">
                                    <input v-model="beneficiarioForm.filiacao[index]
                                        .complement
                                        " type="text" class="h-auto py-2 rounded form-input mb-0" />
                                </div>
                                <div v-else>
                                    {{ filiacao.complement }}
                                </div>
                            </div>
                            <div class="col-span-6 ">
                                <span class="text-sm form-label text-gray-dark">
                                    Cidade/estado
                                </span>
                                <div v-if="isFiliacaoEdit[index]">
                                    <search-select :selected.sync="beneficiarioForm.filiacao[index].city"
                                        :search-keys="['name']" :search-remote="true" :lazy-load="true" disabled
                                        options-url="/api/registry/location?take=50&paginate=false" option-key="id"
                                        :option-value="city =>
                                            city.name +
                                            ' / ' +
                                            city.state.initials
                                            " field-class="py-2" name="city" placeholder="Selecione uma cidade..."
                                        @changed="cityFiliacaoSelected" />

                                    <div v-if="(isNullOrEmpty(beneficiarioForm.filiacao[index].city) &&
                                        beneficiarioForm.filiacao[index].contatos.emails.length > 0 &&
                                        beneficiarioForm.filiacao[index].contatos.emails[0].contact !== '' &&
                                        isNullOrEmpty(beneficiarioForm.filiacao[index].contatos.emails[0].id)) ||
                                        (isNullOrEmpty(beneficiarioForm.filiacao[index].city) &&
                                            beneficiarioForm.filiacao[index].contatos.phones.length > 0 &&
                                            beneficiarioForm.filiacao[index].contatos.phones[0].contact !== '' &&
                                            isNullOrEmpty(beneficiarioForm.filiacao[index].contatos.phones[0].id))"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </div>
                                <div v-else>
                                    {{
                                        filiacao.city !== undefined
                                            ? filiacao.city.name +
                                            " / " +
                                            filiacao.city.state.name
                                            : ""
                                    }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="flex justify-between w-full mb-8 mt-4 border-b-2 border-blue-base bg-white shadow-lg">
                            <span class="mt-2 text-base uppercase text-blue-base">
                                Contato da filiação
                            </span>
                            <button v-if="
                                !getBeneficiarioLoading &&
                                !isFiliacaoContatoEdit
                            " @click="onEditBeneficiarioFiliacaoClick(index)"
                                class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                                <div class="flex text-blue-base items-center justify-center">
                                    Editar
                                </div>
                            </button>
                            <div v-if="
                                !getBeneficiarioLoading &&
                                isFiliacaoContatoEdit
                            " class="flex gap-3">
                                <button @click="cancelEditFiliacaoContato"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                                    <div class="flex text-red-400 items-center justify-center">
                                        Cancelar
                                    </div>
                                </button>
                                <button @click.prevent="$refs.modal.showModal()"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                                    <div class="flex text-green-500 items-center justify-center">
                                        Salvar
                                    </div>
                                </button>
                            </div>
                        </div>
                        <ContactsList :emails="filiacao.contatos.emails" :phones="filiacao.contatos.phones"
                            :emailsForm="beneficiarioForm.filiacao[index].contatos.emails"
                            :phonesForm="beneficiarioForm.filiacao[index].contatos.phones"
                            :readonly="!isFiliacaoContatoEdit">
                        </ContactsList>
                    </div>
                    <a href="#" id="ac"></a>
                    <template v-if="
                        this.beneficiarioForm &&
                        Number(this.beneficiario.tpbeneficiario) ===
                        TipoBeneficiario.ESTUDANTE
                    ">
                        <EstudanteForm :beneficiarioEstudante="beneficiarioEstudante" :beneficiarioEstudanteForm="beneficiarioEstudanteForm
                            " :readonly="!isTrabalhadorOuEstudanteEdit">
                            <button v-if="
                                !getBeneficiarioLoading &&
                                !isTrabalhadorOuEstudanteEdit
                            " @click="
                                onEditBeneficiarioClick(
                                    'trabalhadorOuEstudante'
                                )
                                " class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                                <div class="flex text-blue-base items-center justify-center">
                                    Editar
                                </div>
                            </button>
                            <div v-if="
                                !getBeneficiarioLoading &&
                                isTrabalhadorOuEstudanteEdit
                            " class="flex gap-3">
                                <button @click="cancelEditTrabalhadorOuEstudante()"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                                    <div class="flex text-red-400 items-center justify-center">
                                        Cancelar
                                    </div>
                                </button>
                                <button @click.prevent="$refs.modal.showModal()"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                                    <div class="flex text-green-500 items-center justify-center">
                                        Salvar
                                    </div>
                                </button>
                            </div>
                        </EstudanteForm>
                    </template>
                    <template v-if="
                        this.beneficiarioForm &&
                        Number(this.beneficiario.tpbeneficiario) ===
                        TipoBeneficiario.ESTAGIARIO
                    ">
                        <EstagiarioForm :beneficiarioEstagiario="beneficiarioEstagiario" :beneficiarioEstagiarioForm="beneficiarioEstagiarioForm
                            " :readonly="!isTrabalhadorOuEstudanteEdit">
                            <button v-if="
                                !getBeneficiarioLoading &&
                                !isTrabalhadorOuEstudanteEdit
                            " @click="
                                onEditBeneficiarioClick(
                                    'trabalhadorOuEstudante'
                                )
                                " class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                                <div class="flex text-blue-base items-center justify-center">
                                    Editar
                                </div>
                            </button>
                            <div v-if="
                                !getBeneficiarioLoading &&
                                isTrabalhadorOuEstudanteEdit
                            " class="flex gap-3">
                                <button @click="cancelEditTrabalhadorOuEstudante()"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                                    <div class="flex text-red-400 items-center justify-center">
                                        Cancelar
                                    </div>
                                </button>
                                <button @click.prevent="$refs.modal.showModal()"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                                    <div class="flex text-green-500 items-center justify-center">
                                        Salvar
                                    </div>
                                </button>
                            </div>
                        </EstagiarioForm>
                    </template>
                    <template v-if="
                        this.beneficiarioForm &&
                        Number(this.beneficiario.tpbeneficiario) ===
                        TipoBeneficiario.TRABALHADOR
                    ">
                        <TrabalhadorForm :beneficiarioTrabalhador="beneficiarioTrabalhador"
                            :beneficiarioTrabalhadorForm="beneficiarioTrabalhadorForm
                                " :readonly="!isTrabalhadorOuEstudanteEdit">
                            <button v-if="
                                !getBeneficiarioLoading &&
                                !isTrabalhadorOuEstudanteEdit
                            " @click="
                                onEditBeneficiarioClick(
                                    'trabalhadorOuEstudante'
                                )
                                " class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                                <div class="flex text-blue-base items-center justify-center">
                                    Editar
                                </div>
                            </button>
                            <div v-if="
                                !getBeneficiarioLoading &&
                                isTrabalhadorOuEstudanteEdit
                            " class="flex gap-3">
                                <button @click="cancelEditTrabalhadorOuEstudante()"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                                    <div class="flex text-red-400 items-center justify-center">
                                        Cancelar
                                    </div>
                                </button>
                                <button @click.prevent="$refs.modal.showModal()"
                                    class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                                    <div class="flex text-green-500 items-center justify-center">
                                        Salvar
                                    </div>
                                </button>
                            </div>
                        </TrabalhadorForm>
                    </template>
                    <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                        <span class="mt-2 text-base uppercase text-blue-base">
                            Contato da instituição
                        </span>
                        <button v-if="
                            !getBeneficiarioLoading &&
                            !isContatoInstituicaoEdit
                        " @click="onEditBeneficiarioClick('contatoInstituicaoEdit')"
                            class="px-2 py-2 mb-2 form-new-green sm:py-1 border-blue-base">
                            <div class="flex text-blue-base items-center justify-center">
                                Editar
                            </div>
                        </button>
                        <div v-if="
                            !getBeneficiarioLoading &&
                            isContatoInstituicaoEdit
                        " class="flex gap-3">
                            <button @click="cancelEditContatoInstituicao()"
                                class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                                <div class="flex text-red-400 items-center justify-center">
                                    Cancelar
                                </div>
                            </button>
                            <button @click.prevent="$refs.modal.showModal()"
                                class="px-2 py-2 mb-2 form-new-green sm:py-1 border-green-500">
                                <div class="flex text-green-500 items-center justify-center">
                                    Salvar
                                </div>
                            </button>
                        </div>
                    </div>
                    <ContactsList :emails="contatosInsituicao.emails" :phones="contatosInsituicao.phones"
                        :emailsForm="contatosInsituicaoForm.emails" :phonesForm="contatosInsituicaoForm.phones"
                        :readonly="!isContatoInstituicaoEdit"></ContactsList>
                </template>
            </div>
            <div
                class="mt-4 flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4 justify-between">
                <div class="flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4">
                    <button @click="goBack()" type="button"
                        class="flex items-center justify-center py-2 form-submit md:w-auto" style="background: #828282">
                        Voltar
                    </button>
                </div>
            </div>
        </div>
        <dialog ref="modal">
            <h3>
                Confirmar a edição do beneficiário?
            </h3>
            <div
                class="mt-4 flex items-center justify-center flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4">
                <button type="button" class="flex items-center justify-center py-2 form-submit md:w-auto"
                    style="background: #828282" @click.prevent="$refs.modal.close()">
                    Cancelar
                </button>
                <button type="button" class="flex items-center justify-center py-2 form-submit md:w-auto"
                    @click.prevent="
                        $refs.modal.close();
                    saveBeneficiarioForm();
                    ">
                    Confirmar
                </button>
            </div>
        </dialog>
        <dialog ref="modalEntregaDocumentos" @close="onModalEntregaDocumentosClose()">
            <h3>
                {{ modalEntregaDocumentosData.title }}
            </h3>
            <div
                class="mt-4 flex items-center justify-center flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4">
                <button type="button" class="flex items-center justify-center py-2 form-submit md:w-auto"
                    style="background: #828282" @click.prevent="
                        modalEntregaDocumentosData.action = null;
                    $refs.modalEntregaDocumentos.close();
                    ">
                    Cancelar
                </button>
                <button type="button" class="flex items-center justify-center py-2 form-submit md:w-auto"
                    @click.prevent="$refs.modalEntregaDocumentos.close()">
                    Confirmar
                </button>
            </div>
        </dialog>
    </div>
</template>
<style scoped>
dialog {
    border: 2px solid #1767b2;
    background: white;
    outline: none;
}

dialog::backdrop {
    background: #c0c0c080;
}

h3 {
    color: #1767b2;
    font-weight: 900;
    font-size: 30px;
    text-align: center;
}

.content-pendencia {
    background-color: #ffce9d;
    /* min-height: 100px; */
    width: 400px;
    border: solid 1px black;
}

.content-pendencia.expandido {
    width: 600px;
    top: 0px;
    left: 0px;
    z-index: 1000;
}

.content-pendencia .title span {
    text-decoration: underline;
}

.blur-content {
    filter: blur(2px);
    pointer-events: none;
}
</style>
