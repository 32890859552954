<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import ServicesList from "./partials/ServicesList.vue";

export default {
    components: {
        Breadcrumbs,
        ServicesList
    },
    props: {},
    data() {
        return {
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Linhas", path: "lines.index" },
                { name: "Serviços", path: "lines.bills" },
            ],
            linesServices: [
                { id: 1, name: "Processo de Horário", description: "Solicitação para alterar os horários das linhas regulares.", form: "linesForm" },
                { id: 2, name: "Processo de Alteração de Linha", description: "Alterar alguma característica da linha, quando necessário.", form: "linesForm" },
                { id: 3, name: "Reclassificação da Linha", description: "Alterar a linha dae rodoviária para urbana ou de urbana para rodoviária, se necessário.", form: "linesForm" },
                { id: 4, name: "Alteração de Itinarário", description: "Solicitar, quando identificado, a mudança do destino de uma seção da linha.", form: "linesForm" },
                { id: 5, name: "Cancelamento de Linha", description: "Solicitar quando a linha está descaracterizada e não atende mais os objetivos iniciais.", form: "linesForm" },
                { id: 6, name: "Implantação de Nova Linha", description: "Solicitar quando for identificada a necessidade de atender passageiros que moram em uma área que não possui trânsito de linha.", form: "linesForm" },
                { id: 7, name: "Transferência de Linha/Unidade", description: "Solicitação para transferir a concessão da linha para outra companhia de transporte de passageiros.", form: "linesForm" },
            ],
            sectionServices: [
                { id: 8, name: "Ajuste Tarifário", description: "Reduzir ou aumentar o valor de tarifa da seção, quando necessário.", form: "sectionsForm"  },
                { id: 9, name: "Cancelamento de Seção", description: "Solicitar quando a seção não tiver mais demanda de passageiros.", form: "sectionsForm"  },
            ],
            compServices: [
                { id: 10, name: "Solicitação de Serviço Complementar", description: "Utilizado para quando houver a necessidade de incluir um novo trecho a linha.", form: "compForm"  },
                { id: 11, name: "Cancelamento de Serviço Complementar", description: "Utilizado para cancelar algum tipo de serviço complementar que a concessionária possui (ramal, viagem parcial, serviço residual, serviço de extensão).", form: "compForm"  },
            ],
        };
    },
    methods: {
        toggleList(type) {
            // define refs como um array de keys
            Object.keys(this.$refs).forEach((key) => {
                // verifica se a key contém o tipo selecionado
                if (key.includes(type)) {
                    // se list, remove a classe hidden
                    if (key.includes("list")) {
                        this.$refs[key].classList.remove("hidden");
                    } else {
                        // se button, adiciona a classe selected-button e
                        this.$refs[key].classList.add("selected-button");
                        // remove a classe non-selected-button
                        this.$refs[key].classList.remove("non-selected-button");
                    }
                    // se não contém o tipo selecionado
                } else {
                    // se list, adiciona a classe hidden
                    if (key.includes("list")) {
                        this.$refs[key].classList.add("hidden");
                    } else {
                        // se button, remove a classe selected-button e
                        this.$refs[key].classList.remove("selected-button");
                        // adiciona a classe non-selected-button
                        this.$refs[key].classList.add("non-selected-button");
                    }
                }
            });
        },
    },
    mounted() {},
};
</script>
<style>
button:focus {
    outline: 0!important;
}

.selected-button {
    color: white;
    background-image: linear-gradient(to right, #397dbd, #129dee);
}

.non-selected-button {
    opacity: 0.5;
    background-image: linear-gradient(to right, #8dccf0, #98d8fd);
}
</style>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-2" :crumbs="crumbs" />
            <div class="flex flex-wrap justify-between align-baseline border-b-2 border-blue-base mb-6">
                <h1 class="text-blue-base uppercase pt-5">Serviços</h1>
            </div>
            <div class="grid grid-cols-3 gap-2 text-lg mb-1">
                <button class="selected-button text-center content-center h-16 uppercase shadow-md"
                    @click="toggleList('LinesServices')" ref="buttonLinesServices">
                    Linhas
                </button>
                <button class="non-selected-button text-center content-center h-16 uppercase shadow-md"
                    @click="toggleList('SectionServices')" ref="buttonSectionServices">
                    Seção
                </button>
                <button class="non-selected-button text-center content-center h-16 uppercase shadow-md"
                    @click="toggleList('CompServices')" ref="buttonCompServices">
                    Serviço Complementar
                </button>
            </div>
            <div ref="listLinesServices" class="shadow-md bg-gray-100 p-2">
                <ServicesList :services="linesServices" />
            </div>
            <div ref="listSectionServices" class="hidden shadow-md bg-gray-100 p-2">
                <ServicesList :services="sectionServices" />
            </div>
            <div ref="listCompServices" class="hidden shadow-md bg-gray-100 p-2">
                <ServicesList :services="compServices" />
            </div>
        </div>
    </div>
</template>
