<template>
    <div class="relative h-10" v-click-outside="close">
        <button
            @click="isOpen = !isOpen"
            class="h-full lg:text-right lg:flex lg:items-center text-blue-lightest hover:text-white focus:text-white focus:outline-none"
        >
            <Icon icon="notifications" class="h-5 fill-current" />
            <div
                v-if="unreadNotifications > 0"
                class="absolute top-0 w-4 h-4 ml-2 text-xs leading-tight text-center text-white rounded-full bg-red-base"
            >
                {{ unreadNotifications }}
            </div>
            <Icon icon="arrowDown" class="h-2 ml-1 fill-current" />
        </button>
        <div v-if="isOpen" class="absolute right-0 z-50 w-64 h-64">
            <div
                class="max-h-full overflow-y-auto text-xs bg-white border-4 border-white rounded-sm shadow-md"
            >
                <div
                    class="flex flex-col"
                    v-if="
                        notifications.recent.length || notifications.old.length
                    "
                >
                    <button
                        @click="deleteNotifications"
                        class="w-full p-2 text-right border-b-2 text-gray-dark hover:bg-gray-dark hover:text-white active:bg-red-base focus:outline-none"
                    >
                        Limpar tudo
                    </button>
                    <div class="flex flex-col">
                        <template v-if="notifications.recent.length">
                            <div
                                class="py-1 pl-2 leading-relaxed text-gray-800 border-b-2 border-gray-dark"
                            >
                                Recentes
                            </div>
                            <ul>
                                <li
                                    v-for="(notification,
                                    index) in notifications.recent"
                                    v-bind:key="notification.id"
                                    :class="[
                                        notification.read_at
                                            ? 'bg-gray-light text-gray-dark'
                                            : 'bg-gray-light text-gray-darker',
                                        index > 0 ? 'mt-1' : '',
                                        'w-full h-12 flex justify-between items-center hover:bg-gray-base hover:text-gray-darker active:text-white active:bg-gray-dark focus:outline-none'
                                    ]"
                                >
                                    <router-link
                                        v-if="notification.data.route"
                                        :to="{
                                            name: notification.data.route,
                                            params: notification.data.params
                                        }"
                                        class="px-2 truncate"
                                        :title="notification.data.message"
                                    >
                                        <h1
                                            v-if="notification.data.subject"
                                            class="pt-1 font-bold"
                                        >
                                            {{ notification.data.subject }}
                                        </h1>
                                        {{ notification.data.message }}
                                    </router-link>
                                    <div
                                        v-else
                                        class="px-2 truncate"
                                        :title="notification.data.message"
                                    >
                                        <h1
                                            v-if="notification.data.subject"
                                            class="pt-1 font-bold"
                                        >
                                            {{ notification.data.subject }}
                                        </h1>
                                        {{ notification.data.message }}
                                    </div>
                                    <div class="flex h-full">
                                        <button
                                            @click="
                                                deleteNotification(
                                                    notification.id
                                                )
                                            "
                                            class="flex items-center p-3 border-l-2 hover:bg-red-base hover:text-white active:text-white active:bg-red-800"
                                            title="Excluir"
                                        >
                                            <Icon
                                                icon="close"
                                                class="h-4 fill-current"
                                            />
                                        </button>
                                        <div
                                            v-if="notification.read_at"
                                            title="Lido"
                                            class="flex items-center p-3 border-l-2"
                                        >
                                            <Icon
                                                icon="verified"
                                                class="h-4 fill-current text-green-base"
                                            />
                                        </div>
                                        <button
                                            v-else
                                            @click="markAsRead(notification.id)"
                                            title="Marcar como lido"
                                            class="flex items-center p-3 border-l-2 hover:bg-green-base hover:text-white active:text-white active:bg-green-800"
                                        >
                                            <Icon
                                                icon="verified"
                                                class="h-4 fill-current"
                                            />
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </template>
                        <template v-if="notifications.old.length">
                            <div
                                class="py-1 pl-2 leading-relaxed text-gray-800 border-b-2 border-gray-dark"
                            >
                                Anteriores
                            </div>
                            <ul>
                                <li
                                    v-for="(notification,
                                    index) in notifications.old"
                                    v-bind:key="notification.id"
                                    :class="[
                                        notification.read_at
                                            ? 'bg-gray-light text-gray-dark'
                                            : 'bg-gray-light text-gray-darker',
                                        index > 0 ? 'mt-1' : '',
                                        'w-full h-12 flex justify-between items-center hover:bg-gray-base hover:text-gray-darker active:text-white active:bg-gray-dark focus:outline-none'
                                    ]"
                                >
                                    <router-link
                                        v-if="notification.data.route"
                                        :to="{
                                            name: notification.data.route,
                                            params: notification.data.params
                                        }"
                                        class="px-2 truncate"
                                        :title="notification.data.message"
                                    >
                                        <h1
                                            v-if="notification.data.subject"
                                            class="pt-1 font-bold"
                                        >
                                            {{ notification.data.subject }}
                                        </h1>
                                        {{ notification.data.message }}
                                    </router-link>
                                    <div
                                        v-else
                                        class="px-2 truncate"
                                        :title="notification.data.message"
                                    >
                                        <h1
                                            v-if="notification.data.subject"
                                            class="pt-1 font-bold"
                                        >
                                            {{ notification.data.subject }}
                                        </h1>
                                        {{ notification.data.message }}
                                    </div>
                                    <div class="flex h-full">
                                        <button
                                            @click="
                                                deleteNotification(
                                                    notification.id
                                                )
                                            "
                                            class="flex items-center p-3 border-l-2 hover:bg-red-base hover:text-white active:text-white active:bg-red-800"
                                            title="Excluir"
                                        >
                                            <Icon
                                                icon="close"
                                                class="h-4 fill-current"
                                            />
                                        </button>
                                        <div
                                            v-if="notification.read_at"
                                            title="Lido"
                                            class="flex items-center p-3 border-l-2"
                                        >
                                            <Icon
                                                icon="verified"
                                                class="h-4 fill-current text-green-base"
                                            />
                                        </div>
                                        <button
                                            v-else
                                            @click="markAsRead(notification.id)"
                                            title="Marcar como lido"
                                            class="flex items-center p-3 border-l-2 hover:bg-green-base hover:text-white active:text-white active:bg-green-800"
                                        >
                                            <Icon
                                                icon="verified"
                                                class="h-4 fill-current"
                                            />
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </template>
                    </div>
                </div>
                <div v-else class="w-full p-2 text-right text-gray-dark">
                    Nenhuma notificação
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import differenceInDays from "date-fns/differenceInDays";
import parseISO from "date-fns/parseISO";
import partition from "lodash/partition";
import Icon from "@/core/components/Icon";

export default {
    components: {
        Icon
    },
    data() {
        return {
            isOpen: false,
            api_notifications: []
        };
    },
    created() {
        const handleEscape = e => {
            if (e.key === "Esc" || e.key === "Escape") {
                this.close();
            }
        };
        document.addEventListener("keydown", handleEscape);
        this.$once("hook:beforeDestroy", () => {
            document.removeEventListener("keydown", handleEscape);
        });
    },
    mounted() {
        this.getNotifications();
    },
    computed: {
        notifications: function() {
            if (this.api_notifications.length) {
                let now = new Date();
                let notifications = partition(this.api_notifications, function(
                    n
                ) {
                    return differenceInDays(now, parseISO(n.created_at)) < 2;
                });
                return {
                    recent: notifications[0],
                    old: notifications[1]
                };
            }
            return {
                recent: [],
                old: []
            };
        },
        unreadNotifications: function() {
            const reducer = (accumulator, notification) => {
                if (!notification.read_at) {
                    return accumulator + 1;
                }
                return accumulator;
            };
            return this.api_notifications.reduce(reducer, 0);
        }
    },
    methods: {
        close() {
            this.isOpen = false;
        },
        getNotifications() {
            this.$axios
                .get("/api/notifications")
                .then(response => {
                    this.api_notifications = response.data;
                    setTimeout(this.getNotifications, 120 * 1000);
                })
                .catch(() => {});
        },
        deleteNotifications() {
            this.$swal({
                title: "Confirmar ação",
                text: "As notificações serão excluídas permanentemente.",
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: "Voltar",
                        value: null,
                        visible: true
                    },
                    confirm: {
                        text: "Limpar notificações",
                        value: true
                    }
                }
            }).then(action => {
                if (action) {
                    this.$axios
                        .delete("/api/notifications")
                        .then(response => {
                            this.api_notifications =
                                response.data.notifications;
                            this.$toast.success({
                                title: "OK",
                                message: response.data.message
                            });
                        })
                        .catch(e => {
                            this.$toast.error({
                                title: "",
                                message: e.response.data.message
                            });
                        });
                }
            });
        },
        deleteNotification(id) {
            this.$axios
                .delete("/api/notifications/" + id)
                .then(response => {
                    this.api_notifications = response.data.notifications;
                    this.$toast.success({
                        title: "OK",
                        message: response.data.message
                    });
                })
                .catch(e => {
                    this.$toast.error({
                        title: "",
                        message: e.response.data.message
                    });
                });
        },
        markAsRead(id) {
            this.$axios
                .post("/api/notifications/read/" + id)
                .then(response => {
                    this.api_notifications = response.data.notifications;
                    this.$toast.success({
                        title: "OK",
                        message: response.data.message
                    });
                })
                .catch(e => {
                    this.$toast.error({
                        title: "",
                        message: e.response.data.message
                    });
                });
        }
    }
};
</script>
