<script>
// import Breadcrumbs from "@/core/components/Breadcrumbs";
import SearchSelect from "@/core/components/SearchSelect";
import { lowerCase } from "lodash";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
    components: {
        // Breadcrumbs,
        SearchSelect,
        VueHcaptcha
    },
    data: () => {
        return {
            title: "Linhas",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Linhas", path: "lines.index" },
                { name: "Consulta de Linhas", path: "lines.search.search" }
            ],
            origin: {},
            destiny: {},
            idpontosecao: null,
            originDescription: "",
            destinyDescription: "",
            permitted: false,
        };
    },
    methods: {
        originSelected(origin) {
            this.origin = origin;
        },
        destinySelected(destiny) {
            this.destiny = destiny;
        },
        lineSelected(line) {
            this.number = line;
        },
        capitalize(string) {
            let arrayPalavras = string.split(" ");
            let arrayPalavrasCapitalized = arrayPalavras.map(palavra => {
                return palavra.charAt(0).toUpperCase() + palavra.slice(1);
            });
            return arrayPalavrasCapitalized.join(" ");
        },
        lowerCase(string) {
            return lowerCase(string);
        },
        searchLine() {
            if (!this.permitted) {
                this.$toast.error({
                    message: 'Por favor, complete o captcha para continuar'
                });
                return;
            }
            if (this.origin.idpontosecao && this.destiny.idpontosecao) {
                sessionStorage.setItem('sectionPoints', JSON.stringify({
                    origin: this.origin,
                    destiny: this.destiny
                }));
                this.$router.push({
                    path: `/linhas/consulta/${this.origin.idpontosecao}/${this.destiny.idpontosecao}`
                })
            }
        },
        onVerify(token, eKey) {
            if (token && eKey) {
                this.permitted = true;
            }
        },
        onExpire() {
            this.permitted = false;
        },
        onChallengeExpire: () => {
            // console.log('Challenge expired')
        },
        onError() {
            this.$toast.error({
                message: 'Ocorreu um erro ao validar o captcha, por favor, tente novamente'
            });
            this.permitted = false;
        }
    },
};
</script>
<style>
#bg-image {
    background: url("../../../../core/assets/images/linhas_background.png") no-repeat center center fixed;
}
</style>

<template>
    <div class="w-full bg-white" id="bg-image">
        <div class="px-2 my-4 sm:px-6">
            <!-- <Breadcrumbs class="mb-2" :crumbs="crumbs" />
            <div class="flex flex-wrap justify-between align-baseline border-b-2 border-blue-base mb-6"></div> -->
            <div class="w-full px-24">
                <div class="flex flex-wrap align-baseline border-b-2 border-blue-base mb-2 justify-cen w-full">
                    <h1 class="text-blue-base pt-5">
                        Consulta as viagens disponíveis entre os municípios do Estado de Santa Catarina
                    </h1>
                </div>
                <div class="mb-8">
                    <p class="text-xs text-gray-600">A partir do formulário abaixo, você poderá consultar informações
                        sobre quais são as linhas de transporte coletivo, que realizam um determinado trajeto de
                        interesse. Após a consulta, você poderá identificar características como: ramais, secionamentos,
                        origem, destino, demanda, frequência e valores da linha apresentada.</p>
                </div>
            </div>
            <div class="flex w-full justify-center">
                <div class="lg:w-1/4 md:w-2/5 sm:w-full bg-white shadow-lg rounded-md p-5">
                    <div class="flex flex-wrap align-baseline border-b-2 border-blue-base mb-6 w-full justify-center">
                        <h1 class="text-blue-base pt-5">
                            Selecione seu destino
                        </h1>
                    </div>
                    <form @submit.prevent="searchLine"
                        class="justify grid gap-2 mb-2 w-full md:mb-2 sm:mx-1 relative right-1">
                        <div>
                            <SearchSelect :selected.sync="origin" :search-keys="[]" :search-remote="true"
                                :digits-before-search=3 :lazy-load="true" options-url="/api/lines/section-point/"
                                option-key="nmlocalidade" optional-placeholder-icon="icon_flag.svg"
                                optional-carret-icon="icon_caret.svg" field-class="py-2" name="nmlocalidade"
                                placeholder="Município e Localidade de origem" @changed="originSelected"
                                :option-value="sectionPoint => capitalize(lowerCase(sectionPoint.nmlocalidade)) + ' - ' + capitalize(lowerCase(sectionPoint.delocalidade))">
                            </SearchSelect>
                        </div>
                        <img class="mx-2 h-7 justify-self-center" src="@/core/assets/images/icon_navigation.svg" />
                        <div>
                            <SearchSelect :selected.sync="destiny" :search-keys="[]" :search-remote="true"
                                :digits-before-search="3" :lazy-load="true" options-url="/api/lines/section-point/"
                                option-key="delocalidade" optional-placeholder-icon="icon_location.svg"
                                optional-carret-icon="icon_caret.svg" field-class="py-2" name="delocalidade"
                                placeholder="Município e Localidade de destino" @changed="destinySelected"
                                :option-value="sectionPoint => capitalize(lowerCase(sectionPoint.nmlocalidade)) + ' - ' + capitalize(lowerCase(sectionPoint.delocalidade))">
                            </SearchSelect>
                        </div>
                        <!-- <div class="inline-flex items-center justify-center w-full">
                            <hr class="w-32 h-px my-8 bg-gray-400 border-0 dark:bg-gray-700">
                            <span class="px-2 text-gray-600">ou</span>
                            <hr class="w-32 h-px my-8 bg-gray-400 border-0 dark:bg-gray-700">
                        </div>
                        <div>
                            <SearchSelect :selected.sync="idpontosecao" :search-keys="['line']" :search-remote="true"
                                :lazy-load="true" options-url="/api/registry/location?take=50&paginate=false"
                                option-key="id" field-class="py-2" name="line" placeholder="Selecione a linha..."
                                @changed="lineSelected">
                                >
                            </SearchSelect>
                        </div> -->
                        <div class="flex justify-center mt-4">
                            <button class="flex justify-center w-56 ml-1 form-submit" type="submit">
                                Consultar linha
                            </button>
                        </div>
                        <template>
                            <div class="w-full flex justify-center">
                                <vue-hcaptcha sitekey="7654be3e-40d8-4d62-84fe-c474182c1d1a" @verify="onVerify"
                                    @expired="onExpire" @error="onError"
                                    @challengeExpired="onChallengeExpire"></vue-hcaptcha>
                            </div>
                        </template>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
